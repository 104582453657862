import { getTranslation } from "@/libs/i18n"

export default {
	beforeRouteLeave(to, from, next) {
		this.$store.commit("setTopBarTitle", "")
		next()
	},
	watch: {
		globalTitle: {
			handler(title) {
				if(!!title) this.$store.commit("setTopBarTitle", getTranslation(title))
			},
			immediate: true
		}
	}
}