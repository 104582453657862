<template>
	<v-card v-bind="$attrs">
		<div class="ui-table-container">
			<div class="d-none" ref="columns">
				<slot/>
			</div>
			<div class="ui-table-responsive">
				<table class="ui-draggable-table" :class="tableClasses" :style="tableStyle">
					<colgroup>
						<col v-for="column in columns" :key="column.columnId" :name="column.columnId"/>
					</colgroup>
					<thead v-if="!hideHeaders">
						<tr>
							<th class="ui-table-header" :class="!fixedLayout && 'fit'" :style="fixedLayout && { width: 56 }" v-if="!disabled"></th>
							<ui-table-header v-for="(column, index) in columns" :key="`ui-table-header-${index}`" :column="column" :fixed-layout="fixedLayout"/>
						</tr>
					</thead>
					<draggable tag="tbody" v-model="sortableRows" handle=".ui-table-dragger">
						<tr v-for="(item, i) in sortableRows">
							<td :class="!fixedLayout && 'fit'" :style="fixedLayout && { width: 56 }" v-if="!disabled">
								<v-icon class="ui-table-dragger">fas fa-align-justify</v-icon>
							</td>
							<ui-table-cell v-for="(column, j) in columns" :key="`ui-table-cell-${i}-${j}`" :column="column" :item="item" :row-key="getKey(item, i)"/>
						</tr>
						<tr v-if="!sortableRows.length" class="no-items">
							<td :colspan="columns.length + (disabled ? 0 : 1)">
								<slot name="noItems">
									<span class="d-block px-2 text-center">{{ $t(noItemsText) }}</span>
								</slot>
							</td>
						</tr>
					</draggable>
				</table>
			</div>
		</div>
	</v-card>
</template>

<script>
	import Draggable from "vuedraggable"
	import UiTableCell from "./ui-table-cell"
	import UiTableHeader from "./ui-table-header"
	import Themeable from "vuetify/lib/mixins/themeable"

	export default {
		name: "ui-draggable-table",
		components: { UiTableCell, UiTableHeader, Draggable },
		mixins: [Themeable],
		inheritAttrs: false,
		model: {
			prop: "items",
			event: "sorted"
		},
		props: {
			items: { type: Array, default: () => ([]) },
			hideHeaders: Boolean,
			rowClass: [String, Function],
			noItemsText: { type: String, default: "default.no-item" },
			itemKey: [String, Function],
			itemIndex: { type: String, default: "index" },
			bordered: Boolean,
			striped: Boolean,
			dense: Boolean,
			hoverable: { type: Boolean, default: true },
			tableStyle: [String, Object],
			fixedLayout: Boolean,
			transform: Function,
			disabled: Boolean
		},
		data() {
			return {
				columns: []
			}
		},
		computed: {
			tableId() { return this._.uniqueId("ui-draggable-table-") },
			tableClasses() {
				return {
					bordered: this.bordered,
					striped: this.striped,
					dense: this.dense,
					hoverable: this.hoverable,
					"fixed-layout": this.fixedLayout,
					...this.themeClasses
				}
			},
			hasExpansion() { return false },
			sortableRows: {
				get() { return this.items },
				set(sortedRows) {
					//if(!this._.isNil(this.transform)) this.$emit("sorted", this._.map(sortedRows, (item, index) => this.transform(item, index)))
					//else this.$emit("sorted", sortedRows)
					const transform = !this._.isNil(this.transform) ? this.transform : (item, index) => {
						this._.set(item, this.itemIndex, index)
						return item
					}
					this.$emit("sorted", this._.map(sortedRows, transform))
				}
			}
		},
		methods: {
			getColumnIndex(column) {
				return [].indexOf.call(this.$refs.columns.children, column.$el)
			},
			addColumn(column) {
				const index = this.getColumnIndex(column)
				column.columnIndex = index
				console.debug("ui-table add column", column.columnId, "at index", index)
				this.columns.splice(index, 0, column)
			},
			removeColumn(column) {
				const index = column.columnIndex
				if (index >= 0) {
					console.debug("ui-table remove column", column.columnId, "at index", index)
					this.columns.splice(index, 1)
				}
			},
			getRowClass(row) {
				if (!this._.isNil(this.rowClass)) {
					if (this._.isFunction(this.rowClass)) return this.rowClass(row)
					else return this.rowClass
				}
			},
			getKey(item, index) {
				if (!!this.itemKey) {
					if (this._.isString(this.itemKey)) return this._.get(item, this.itemKey)
					else return this.itemKey(item)
				} else return index
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/style/ui-table";
	.ui-table-dragger {
		cursor: ns-resize;
	}
</style>