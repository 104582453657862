<script>
	import RenderHelperMixin from "@/mixins/render-helper-mixin"
	import { modelMixinFactory } from "@/mixins/model-mixin"

	export default {
		name: "ui-search-field",
		mixins: [RenderHelperMixin, modelMixinFactory(String, "ricerca", 300)],
		model: {
			prop: "fieldValue"
		},
		props: {
			solo: { type: Boolean, default: true },
			placeholder: { type: String, default: 'default.search' },
			name: { type: String, default: "ricerca" },
			searchIcon: { type: String, default: "fas fa-search" },
			counter: { type: [Number, Boolean], default: 50 }
		},
		render(h) {
			const attrs = {
				type: "text",
				value: this.ricerca,
				name: this.name,
				placeholder: this._.capitalize(this.$t(this.placeholder)),
				solo: this.solo,
				prependInnerIcon: this.searchIcon,
				clearable: true,
				clearIcon: "fas fa-trash-alt fa-md",
				clearPosition: "append"
			}
			if(this.counter !== false) {
				attrs.counter = this._.isNumber(this.counter) ? this.counter : 50
				this.rules = `max:${attrs.counter}`
			}
			const data = {
				on: Object.assign({}, this.$listeners, { input: this.input }),
				attrs: Object.assign({}, this.$attrs, attrs)
			}
			return this.renderComponentWithSlots(h, "ui-field", data)
		}
	}
</script>