<template lang="pug">
div(class="ui-datetime-field w-100 p-relative")
	v-menu(v-model="show", :close-on-content-click="false", :close-on-click="false", top, transition="slide-y-transition", max-width="290px", min-width="290px")
		template(#activator="{ on, attrs }")
			ui-output-field(:value="dateTimeFormatted", v-if="readonly", :prepend-icon="calendarIcon", v-bind="{ ...$attrs, ...attrs }")
			v-text-field(:value="dateTimeFormatted", v-else, readonly, :prepend-icon="calendarIcon", v-bind="{ ...$attrs, ...attrs }", v-on="on")
				template(#append-outer, v-if="isClearable")
					v-icon(@click="clear") far fa-trash-alt clear-icon
		validation-observer(slim, #default="{ handleSubmit }", ref="validator")
			v-card
				v-tabs(grow, v-model="tab")
					v-tab
						v-icon far fa-calendar-alt
					v-tab
						v-icon far fa-clock
				v-divider
				v-tabs-items(v-model="tab")
					v-tab-item(eager)
						validation-provider(name="data", rules="required", #default="{ errors }")
							v-date-picker(v-model="datePart", color="primary", full-width)
							p(v-for="(error, index) in errors", :key="`date-error-${index}`", class="error--text") {{error}}
					v-tab-item(eager)
						validation-provider(name="ora", rules="required", #default="{ errors }")
							v-time-picker(v-model="time", format="24hr", color="primary", :use-seconds="allowSeconds", full-width)
							p(v-for="(error, index) in errors", :key="`time-error-${index}`", class="error--text") {{error}}
				v-divider
				v-card-actions
					v-spacer
					v-btn(text, color="secondary", @click="closePicker") annulla
					v-btn(text, color="primary", @click="handleSubmit(doInput)") ok
</template>

<script>
export default {
	name: "ui-datetime-field",
	props: {
		value: String,
		readonly: Boolean,
		allowSeconds: Boolean,
		icon: { type: [String, Boolean], default: "far fa-calendar-alt" },
		clearable: Boolean
	},
	data() {
		return {
			show: false,
			tab: 0,
			date: null,
			time: null
		}
	},
	computed: {
		timeFormat() {
			if(this.allowSeconds) return "HH:mm:ss"
			else return "HH:mm"
		},
		dateTimeFormatted() {
			if(this.$isTruthy(this.value)) return this.$moment(this.value).format(`DD-MM-YYYY ${this.timeFormat}`)
			else return ""
		},
		datePart: {
			get() { return this.date },
			set(date) {
				this.date = date
				if(!this.$isTruthy(this.time)) this.tab = 1
			}
		},
		calendarIcon() { if(this.icon !== false) return this.icon },
		isClearable() { return this.clearable && this.$isTruthy(this.value) }
	},
	methods: {
		doInput() {
			let dateTime = `${this.date}T${this.time}`
			if(!this.allowSeconds) dateTime += ":00"
			this.$emit("input", dateTime)
			this.closePicker()
		},
		closePicker() {
			this.show = false
			this.$refs.validator && this.$refs.validator.reset()
		},
		resetInput() {
			this.tab = 0
			this.date = null
			this.time = null
		},
		clear() {
			this.$emit("input", "")
			this.resetInput()
		}
	},
	watch: {
		value: {
			handler(value) {
				if(this.$isTruthy(value)) {
					this.date = this.$moment(value).format("YYYY-MM-DD")
					this.time = this.$moment(value).format(this.timeFormat)
				} else this.resetInput()
			},
			immediate: true
		}
	}
}
</script>