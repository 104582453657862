<script>
export default {
	name: "ui-table-cell",
	props: {
		column: {
			type: Object,
			required: true
		},
		item: {required: true},
		rowKey: [Number, String]
	},
	computed: {
		classes() {
			return this.column.cellClasses
		}
	},
	render(h) {
		const attrs = {}
		const rowspan = this.column.getRowspan(this.item), colspan = this.column.getColspan(this.item)
		if(!this._.isNil(rowspan)) attrs.rowspan = rowspan
		if(!this._.isNil(colspan)) attrs.colspan = colspan
		const cellData = {
			attrs,
			class: {
				"ui-table-cell": true,
				...this.classes
			}
		}
		const children = [this.column.renderCellValue(this.item, this.rowKey)]
		return h("td", cellData, children)
	}
}
</script>