import { debounce as deb, get, cloneDeepWith } from "lodash"

export function expandModelToComputedProperties(props = [], model = "model", event = "input") {
	return props.reduce((ctx, prop) => {
		ctx[prop] = {
			get() { return get(this[model], prop) },
			set(value) {
				console.debug("expanded model set value", prop, value, this[model])
				this.$emit(event, Object.assign({}, this[model], { [prop]: value }))
			}
		}
		return ctx
	}, {})
}

export function cloneValue(value) {
	return cloneDeepWith(value, val => {
		if(val instanceof File) return val
	})
}

export function modelMixinFactory(prop = { required: false }, model = "model", debounce = 0, inputProp = "value", inputEvent = "input") {
	function emitInput(value) {
		if(!this._.isEqual(this[inputProp], value)) {
			console.debug(this.$options._componentTag, "model-mixin emit", inputEvent, value)
			this.$emit(inputEvent, value)
			this.onInput()
		} else console.debug(this.$options._componentTag, "model-mixin NOT emit input because values are identical", this[inputProp], value)
	}
	const input = debounce > 0 ? deb(emitInput, debounce) : emitInput
	const validPositions = ["prepend", "prepend-inner", "append", "append-outer"]
	return {
		model: {
			prop: inputProp,
			event: inputEvent
		},
		props: {
			[inputProp]: prop,
			defaultValue: { required: false },
			clearIcon: { type: String, default: "far fa-trash-alt clear-icon" },
			clearPosition: { type: String, default: "append", validator: position => validPositions.indexOf(position) !== -1 }
		},
		data() {
			return {
				[model]: null
			}
		},
		computed: {
			isClearable() { return false },
			isMultiple() { return false },
			hasValue() {
				const value = this[model]
				if(this._.isNil(value)) return false
				if((this._.isString(value) || this.isMultiple) && this._.isEmpty(value)) return false
				return true
			},
			clearIconPosition() {
				switch(this.clearPosition) {
					case "prepend": return "prependIcon"
					case "prepend-inner": return "prependInnerIcon"
					case "append": return "appendIcon"
					case "append-outer": return  "appendOuterIcon"
				}
			},
			clearButton() { if(this.isClearable && this.hasValue) return { [this.clearIconPosition]: this.clearIcon } },
			clearEvent() { if(this.isClearable) return { [`click:${this.clearPosition}`]: this.clear } }
		},
		methods: {
			initModel(force = false) {
				if(force || !this._.isEqual(this[model], this[inputProp])) {
					console.debug(this.$options._componentTag, "model-mixin init model", model, this[inputProp])
					this[model] = this.cloneValue(this[inputProp])
					this.onInit(this[model])
				} else console.debug(this.$options._componentTag, "model-mixin init model: content are identical")
			},
			onInit(model) { /* viene chiamato quando si utilizza il metodo initModel() */ },
			input,
			onInput() { /* viene chiamato quando si utilizza il metodo input() */ },
			clear() {
				console.debug("model-mixin clear value", this.$options._componentTag)
				if(this.isMultiple) this[model] = []
				else this[model] = null
				this.$emit("cleared", true)
			},
			cloneValue: cloneValue
		},
		watch: {
			[inputProp]: {
				handler() { this.initModel() },
				immediate: true
			},
			[model]: "input"
		},
		mounted() {
			if(!this.hasValue && !this._.isUndefined(this.defaultValue)) this.input(this.cloneValue(this.defaultValue))
		}
	}
}

export default modelMixinFactory()