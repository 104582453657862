<template>
	<v-badge color="error" :content="errorsCount" :value="errorsCount" bordered v-bind="badgeProps">
		<v-icon v-bind="$attrs">{{icon}}</v-icon>
	</v-badge>
</template>

<script>
	export default {
		name: "ui-error-icon",
		props: {
			icon: { type: String, required: true },
			errorsCount: { type: Number, default: 0 },
			badgeProps: { type: Object, default: () => ({ offsetY: 10 }) }
		}
	}
</script>