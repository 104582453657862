<script>
	import { VSelect, VCombobox, VAutocomplete } from "vuetify/lib"
	import RenderHelperMixin from "@/mixins/render-helper-mixin"
	import ModelMixin from "@/mixins/model-mixin"
	import I18nFormatterMixin from "@/mixins/formatter/i18n-formatter-mixin"
	import FunctionFormatterMixin from "@/mixins/formatter/function-formatter-mixin"
	import LoadingMixin from "@/mixins/loading-mixin"
	import { getPropertyFromItem } from "vuetify/lib/util/helpers"
	import { getTranslation } from "@/libs/i18n"
	
	export default {
		name: "ui-select",
		mixins: [RenderHelperMixin, ModelMixin, I18nFormatterMixin, FunctionFormatterMixin, LoadingMixin],
		components: { VSelect, VCombobox, VAutocomplete },
		props: {
			label: { type: String, default: "" },
			value: { required: false },
			items: { type: Array, default: () => ([]) },
			search: Boolean,
			combo: Boolean,
			itemValue: { type: [String, Array, Function], default: "value" },
			itemGroup: [String, Function],
			translateGroup: [String, Function],
			multiple: Boolean,
			clearable: Boolean
		},
		computed: {
			isClearable() { return this.clearable },
			isMultiple() { return this.multiple },
			needFormat() { return !this._.isNil(this.i18nPrefix) || !this._.isNil(this.format) },
			component() {
				if (this.combo) return "v-combobox"
				if (this.search) return "v-autocomplete"
				return "v-select"
			},
			selectItems() {
				if(this._.isNil(this.itemGroup)) return this.translateItems(this.items)
				else return this._.chain(this.items).groupBy(item => {
					const group = this._.isFunction(this.itemGroup) ? this.itemGroup(item) : this._.get(item, this.itemGroup)
					if (!this._.isNil(this.translateGroup)) {
						if(this._.isFunction(this.translateGroup)) return this.translateGroup(group)
						else {
							const i18nKey = `${this.translateGroup}.${group}`
							if(this.$te(i18nKey)) return this.$t(i18nKey)
						}
					}
					return group
				}).reduce((items, grouped, group) => {
					items.push({ header: group })
					items.push(...this.translateItems(grouped))
					return items
				}, []).value()
			},
			clearIconPosition() { return "appendOuterIcon" },
			clearEvent() { if(this.isClearable) return { ["click:append-outer"]: this.clear } }
		},
		methods: {
			getValue(item) { return getPropertyFromItem(item, this.itemValue, item) },
			formatValue(value) {
				if(!!value) {
					if (!this._.isNil(this.i18nPrefix)) return this.i18nFormat(value)
					else if(!this._.isNil(this.format)) return this.translate(value)
				}
				return value
			},
			translateItems(items) {
				if(!this.needFormat) return items
				else return this._.map(items, item => {
					const value = this.getValue(item)
					return { value, text: this.formatValue(value) }
				})
			}
		},
		render(h) {
			const attrs = Object.assign({ appendIcon: "fas fa-chevron-down fa-sm" }, this.$attrs, { value: this.value, items: this.selectItems, multiple: this.isMultiple, loading: this.lazyLoading }, this.clearButton, { label: getTranslation(this.label) })
			if(!this.needFormat) attrs.itemValue = this.itemValue
			const data = { attrs, on: Object.assign({}, this.$listeners, this.clearEvent) }
			return this.renderComponentWithSlots(h, this.component, data)
		}
	}
</script>