import axios from "axios"
import store from "../store"
import { get } from "lodash"

class Nais {

	async fetchVersion() {
		const response = await axios.get("/admin/config/nais-version")
		const version = get(response, "data.version", "")
		store.commit("setVersion", version)
		return version
	}

}

export default new Nais()