<template lang="pug">
	v-container(fluid, pa-0)
		v-scroll-y-transition(mode="out-in")
			v-row(v-if="window === 'table'")
				v-col(cols="12", v-if="hasErrors", class="pb-0")
					span(class="d-block error--text", v-for="(error, index) in errorMessages", :key="`ui-edit-table-error-${index}`") {{error}}
				v-col(cols="12", :class="{ 'pt-0': hasErrors }")
					slot(name="table", v-bind="{ crea, modifica, cancella }")
						component(:is="tableComponent", flat, v-bind="tableProps", v-on="tableListeners", :item-key="itemKey", @sorted="$emit('input', $event)")
							ui-table-column(fit, hidden-xs-only, #default="{ item }", v-if="canEdit", v-bind="editColumnAttrs")
								slot(name="edit-btn", v-bind="{ modifica: _.partial(modifica, item), item }")
									v-tooltip(right, :disabled="!hasEditTooltip")
										template(#activator="{ on }")
											v-btn(small, color="primary", class="action-btn", v-on="on", @click="modifica(item)")
												v-icon(small) {{getEditIcon(item)}}
										span(v-html="getEditTooltip(item)")
							slot
							ui-table-column(fit, hidden-xs-only, v-if="canCreate || canDelete", v-bind="createAndDeleteColumnAttrs")
								template(#header, v-if="canCreate")
									slot(name="create-btn", v-bind="{ hasCreateTooltip, crea, createIcon, createItemTooltip }")
										v-tooltip(left, :disabled="!hasCreateTooltip")
											template(#activator="{ on }")
												v-btn(small, color="primary", class="action-btn", v-on="on", @click="crea", :disabled="createDisabled")
													v-icon(small) {{createIcon}}
											span(v-html="createItemTooltip")
								template(#default="{ item }", v-if="canDelete")
									slot(name="delete-btn", v-bind="{ cancella: _.partial(cancella, item), item, deleteIcon, tooltipMessage: getDeleteTooltip(item) }")
										v-tooltip(left, :disabled="!hasDeleteTooltip")
											template(#activator="{ on }")
												v-btn(small, color="primary", class="action-btn", v-on="on", @click="cancella(item)")
													v-icon(small) {{deleteIcon}}
											span(v-html="getDeleteTooltip(item)")
			v-row(v-if="window === 'editor'", :key="editorKey")
				v-col(cols="12")
					validation-observer(slim, #default="{ handleSubmit }", ref="item-observer")
						v-card
							ui-page-loader(v-if="creating", :message="creatingMessage")
							template(v-else-if="editing")
								v-toolbar(flat)
									v-toolbar-title
										span(v-html="editorTitle")
								v-card-text(:class="prominent && 'pa-0'")
									slot(name="editor", v-bind="editor")
								v-divider
								v-card-actions(v-if="!hideActions")
									slot(name="editor-actions", v-bind="{ chiudiEditor, handleSubmit, actionsDisabled, editor }")
										v-spacer
										v-btn(small, outlined, color="secondary", @click="chiudiEditor", :disabled="actionsDisabled")
											span(v-html="closeBtnLabel")
										v-btn(small, outlined, color="primary", @click="handleSubmit(salva)", :disabled="saveDisabled || actionsDisabled", :loading="remoteUpdating", v-if="saveBtnShown")
											span(v-html="saveBtnLabel")
</template>

<script>
import { getTranslation } from "@/libs/i18n"

const defaultModel = {
	index: -1,
	item: null,
	original: null
}

export default {
	name: "ui-edit-table",
	inheritAttrs: false,
	props: {
		value: { type: Array, default: () => ([]) },
		noCreate: Boolean,
		createIcon: { type: String, default: "fas fa-plus" },
		createTooltip: String,
		createItem: Function,
		createTitle: String,
		createDisabled: Boolean,
		creatingMessage: { type: String, default: "default.creating" },
		noEdit: Boolean,
		editIcon: { type: [String, Function], default: "fas fa-pencil-alt" },
		editTooltip: { type: [String, Function], default: "default.edit" },
		editTitle: [String, Function],
		noDelete: Boolean,
		deleteIcon: { type: String, default: "fas fa-trash-alt" },
		deleteTooltip: [String, Function],
		confirmDelete: [String, Function],
		form: [Object, Function],
		errorMessages: Array,
		noEditor: Boolean,
		prominent: Boolean,
		itemKey: [String, Function],
		draggable: Boolean,
		saveDisabled: false,
		hideActions: Boolean,
		actionsDisabled: Boolean,
		readonly: Boolean,
		api: [String, Object],
		remoteUpdate: Function,
		closeLabel: { type: [String, Function], default: "default.cancel" },
		saveLabel: { type: [String, Function], default: "default.confirm" },
		hideSaveBtn: [Boolean, Function],
		editColumnAttrs: Object,
		createAndDeleteColumnAttrs: Object
	},
	data() {
		return {
			window: "table",
			lazyItems: [],
			creating: false,
			model: { ...defaultModel },
			remoteUpdating: false
		}
	},
	computed: {
		isRemote() { return !this._.isNil(this.api) },
		tableComponent() { return this.draggable ? "ui-draggable-table" : this.isRemote ? "ui-remote-table" : "ui-table" },
		tableProps() {
			const props = this.isRemote ? { api: this.api } : { items: this.value }
			return Object.assign({}, this.$attrs, props)
		},
		tableListeners() {
			const listeners = this.isRemote ? { "items:fetched": items => this.lazyItems = items } : {}
			return Object.assign({}, this.$listeners, listeners)
		},
		createItemTooltip() {
			const tooltip = this.createTooltip || this.createTitle
			if(tooltip) return getTranslation(tooltip)
		},
		hasCreateTooltip() { return !this._.isNil(this.createItemTooltip) },
		hasEditTooltip() { return !this._.isNil(this.editTooltip) },
		hasDeleteTooltip() { return !this._.isNil(this.deleteTooltip) },
		canCreate() { return !this.noCreate && !this._.isNil(this.createItem) },
		canEdit() { return !this.noEdit && !this.noEditor },
		canDelete() { return !this.noDelete },
		isUpdate() { return this.model.index >= 0 },
		editing() { return this.window === "editor" },
		editorTitle() {
			if(this.isUpdate) {
				if(this._.isFunction(this.editTitle)) return this.editTitle(this.model.original)
				else if(this._.isNil(this.editTitle)) return this.getEditTooltip(this.model.original)
				else return getTranslation(this.editTitle)
			} else if(this._.isNil(this.createTitle)) return getTranslation(this.createTooltip)
			else return getTranslation(this.createTitle)
		},
		editor() {
			//todo: controllare dove viene usato editing e sostituirlo con isUpdate
			const props = { isUpdate: this.isUpdate, editing: this.isUpdate, ...this.model }
			if(!this._.isNil(this.form)) {
				if(this._.isFunction(this.form)) this._.merge(props, this.form(this.model))
				else this._.merge(props, this.form)
			}
			return props
		},
		hasErrors() {
			if(this._.isNil(this.errorMessages)) return false
			return !this._.isEmpty(this.errorMessages)
		},
		editorKey() {
			if(!this._.isNil(this.itemKey)) {
				if (this._.isString(this.itemKey)) return this._.get(this.model.item, this.itemKey)
				else return this.itemKey(this.model.item)
			}
			return `nested-editor-${this.model.index}`
		},
		closeBtnLabel() {
			if(this._.isFunction(this.closeLabel)) return this.closeLabel(this.editor)
			else return getTranslation(this.closeLabel)
		},
		saveBtnLabel() {
			if(this._.isFunction(this.saveLabel)) return this.saveLabel(this.editor)
			else if(this._.isNil(this.saveLabel)) return this.editorTitle
			else return getTranslation(this.saveLabel)
		},
		saveBtnShown() {
			if(this._.isFunction(this.hideSaveBtn)) return !this.hideSaveBtn(this.editor)
			return !this.hideSaveBtn
		}
	},
	methods: {
		async initModel() { this.model = { index: -1, item: await this.createItem(), original: null } },
		updateItems() { this.$emit("input", this.lazyItems) },
		async crea() {
			if(this.canCreate) try {
				this.creating = true
				await this.initModel()
				this.apriEditor()
			} finally { this.creating = false }
		},
		modifica(original) {
			const index = this._.findIndex(this.lazyItems, original)
			if(index >= 0) {
				const item = this._.cloneDeep(this.lazyItems[index])
				this.model = { index, item, original }
				this.$emit("selected", this.model)
				this.apriEditor()
			}
		},
		getEditTooltip(item) {
			if(this.hasEditTooltip) {
				if(this._.isString(this.editTooltip)) return getTranslation(this.editTooltip)
				else return this.editTooltip(item)
			}
			return ""
		},
		getEditIcon(item) {
			if(this._.isFunction(this.editIcon)) return this.editIcon(item)
			else return this.editIcon
		},
		async cancella(item) {
			let confirm = true
			if(!this._.isNil(this.confirmDelete)) {
				const message = this._.isFunction(this.confirmDelete) ? this.confirmDelete(item) : this.confirmDelete
				confirm = await this.$confirm(message, "error")
			}
			if(confirm) {
				const index = this._.findIndex(this.lazyItems, item)
				if (index >= 0) {
					this.lazyItems.splice(index, 1)
					this.$emit("item-deleted", item)
					this.updateItems()
				}
			}
		},
		getDeleteTooltip(item) {
			if(this.hasDeleteTooltip) {
				if(this._.isString(this.deleteTooltip)) return getTranslation(this.deleteTooltip)
				else return this.deleteTooltip(item)
			}
			return ""
		},
		apriEditor() {
			if(!this.noEditor) {
				this.window = "editor"
				this.$emit("editing", true)
			}
		},
		chiudiEditor() {
			this.window = "table"
			//this.$refs["item-observer"].reset()
			this.model = { ...defaultModel }
			this.$emit("editing", false)
		},
		async salva() {
			let procedi = true
			if(!this._.isNil(this.remoteUpdate)) try {
				this.remoteUpdating = true
				procedi = await this.remoteUpdate(this.model.item)
			} finally { this.remoteUpdating = false }
			if(procedi === false) return
			if(this.isUpdate) {
				this.lazyItems.splice(this.model.index, 1, this.model.item)
				this.$emit("item-updated", this.model.item)
			} else {
				this.lazyItems.push(this.model.item)
				this.$emit("item-created", this.model.item)
			}
			this.$emit("updated", this.model.item)
			this.updateItems()
			this.chiudiEditor()
		},
		resetValidationObserver() {
			this.$refs["item-observer"] && this.$refs["item-observer"].reset()
		}
	},
	watch: {
		value: {
			handler(items) { this.lazyItems = items },
			immediate: true
		},
		window: "resetValidationObserver"
	},
	mounted() { if(this.canCreate) this.initModel() }
}
</script>