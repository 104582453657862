<script>
	import UiTable from "./ui-table"
	import ApiMixin from "@/mixins/api-mixin"
	import { debounce } from "lodash"

	export const TOTAL_HEADER = process.env.VUE_APP_TOTAL_HEADER

	export default {
		name: "ui-remote-table",
		extends: UiTable,
		mixins: [ApiMixin],
		data() {
			return {
				totalItems: 0,
				pageResponse: null
			}
		},
		computed: {
			fetchConfig() {
				const config = {...this.apiConfig}
				if (this.paginationEnabled) config.params = this._.defaults({}, this.paginationParams, config.params)
				return config
			}
		},
		methods: {
			async fetchItems() {
				try {
					this.lazyLoading = true
					const response = await this.axios.request(this.fetchConfig)
					if(this.paginationEnabled) {
						this.rows = this._.get(response, "data.items", [])
						this.totalItems = this._.get(response, "data.total", 0)
						this.pageResponse = this._.get(response, "data", {})
					} else this.rows = response.data
					this.$emit("items:fetched", this.rows)
				} catch (e) { this.$emit("fetch-error", e) }
				finally { this.lazyLoading = false }
            },
			doPagination: debounce(async function () {
				console.debug("ui-remote-table do pagination", this.sort, this.order)
				await this.fetchItems()
			}, 10),
			getTotalItems() { return this.totalItems },
			getOthersPaginationBindings() {
				return { response: this.pageResponse }
			},
			reload() {
				this.page = 1
				this.doPagination()
			}
		},
		watch: {
			apiConfig: "reload"
		}
	}
</script>