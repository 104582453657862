import { parseRolesArray } from "@/libs/security"
import { reduce, startsWith, pick, isNil } from "lodash"

function buildUser(userInfo) {
	return pick(userInfo, ["id", "username", "nominativo", "gruppo", "email"])
}

const errors = { login: false, loginAs: false }

export default {
	state: {
		loginAs: [],
		authenticated: false,
		errors: { ...errors }
	},
	getters: {
		isAuthenticated: state => state.authenticated,
		isImpersonated: state => state.loginAs.length > 0,
		isMultipleImpersonated: state => state.loginAs.length > 1,
		authorities: (state, getters) => {
			if(getters.isAuthenticated) return getters.userInfo.authorities || []
			else return []
		},
		utente: (state, getters) => buildUser(getters.userInfo),
		agencyRoles: (state, getters) => getters.userInfo?.agencyRoles,
		accessoOriginale: (state, getters) => getters.isImpersonated ? state.loginAs[0] : null,
		utenteOriginale: (state, getters) => getters.isImpersonated ? buildUser(getters.accessoOriginale) : null,
		accessoPrecedente: (state, getters) => getters.isImpersonated ? state.loginAs[state.loginAs.length - 1] : null,
		utentePrecedente: (state, getters) => getters.isImpersonated ? buildUser(getters.accessoPrecedente) : null,
		hasAnyRoles: (state, getters) => (...roles) => {
			const authorities = getters.authorities
			return reduce(parseRolesArray(...roles), (check, role) => {
				if(authorities.indexOf(role) >= 0) return true
				return check
			}, false)
		},
		hasAnyGroup: (state, getters) => (...roles) => {
			const gruppo = getters.utente.gruppo
			return reduce(parseRolesArray(...roles), (check, role) => {
				if(gruppo === role) return true
				return check
			}, false)
		},
		hasAnyParents: (state, getters) => (parent, ...roles) => {
			const roleDetails = getters.userInfo?.roleDetails
			return reduce(parseRolesArray(...roles), (check, role) => {
				if(roleDetails && roleDetails[role] && roleDetails[role].parent && roleDetails[role].parent === parent) return true
				return check
			}, false);
		},
		hasNoAnyParents: (state, getters) => (...roles) => {
			const roleDetails = getters.userInfo?.roleDetails
			return reduce(parseRolesArray(...roles), (check, role) => {
				if(roleDetails && roleDetails[role] && roleDetails[role].parent) return check
				return true
			}, false);
		},
		loginError: state => state.errors.login,
		loginAsError: state => state.errors.loginAs
	},
	mutations: {
		setAuthenticated: (state, authenticated) => state.authenticated = authenticated,
		loginAs: (state, currentUser) => {
			console.debug("commit login as from", currentUser)
			state.loginAs.push(currentUser)
		},
		popLoginAsStack: state => {
			if(state.loginAs.length > 0) state.loginAs.pop()
		},
		clearLoginAsStack: state => {
			if(state.loginAs.length > 0) state.loginAs = []
		},
		setLoginError: (state, value) => state.errors.login = value,
		setLoginAsError: (state, value) => state.errors.loginAs = value,
		clear: state => {
			state.loginAs = []
			state.errors = { ...errors }
			state.authenticated = false
		}
	},
	actions: {
		logout({ commit }) { commit("clear") },
		logoutToPrevious({ commit, dispatch, getters }) {
			if(getters.isImpersonated) commit("popLoginAsStack")
			else dispatch("logout")
		},
		logoutToOriginal({ commit, dispatch, getters }) {
			if(getters.isImpersonated) commit("clearLoginAsStack")
			else dispatch("logout")
		}
	}
}