<script>
	import { VTextField, VLabel } from "vuetify/lib"
	import ValueFormatterMixin from "@/mixins/value-formatter-mixin"
	import { getTranslation } from "@/libs/i18n"

	export default {
		name: "ui-output-field",
		extends: VTextField,
		mixins: [ValueFormatterMixin],
		props: {
			dense: { type: Boolean, default: true },
			textarea: Boolean,
			disabled: Boolean,
			reverse: Boolean,
			truncate: Boolean,
			hideDashedBorder: Boolean
		},
		computed: {
			classes() {
				return {
					...VTextField.options.computed.classes.call(this),
					"ui-output-field": true,
					"hide-dashed-border": this.hideDashedBorder
				}
			}
		},
		methods: {
			genLabel() {
				if (!this.showLabel) return null
				const data = {
					props: {
						absolute: true,
						color: this.validationState,
						dark: this.dark,
						disabled: this.isDisabled,
						focused: !this.isSingle && (this.isFocused || !!this.validationState),
						left: this.labelPosition.left,
						light: this.light,
						right: this.labelPosition.right,
						value: this.labelValue
					}
				}
				return this.$createElement(VLabel, data, getTranslation(this.$slots.label || this.label));
			},
			genInput() {
				if(!this._.isNil(this.$slots.default)) return this.$slots.default
				const data = {
					domProps: {
						innerHTML: this.formattedValue
					},
					style: { cursor: "default" },
					attrs: {
						...this.attrs$,
						id: this.computedId,
						readonly: true,
						autofocus: this.autofocus,
						disabled: this.disabled
					},
					class: {
						"ui-output-content": true,
						"ui-output-textarea": this.textarea,
						"ui-output-content-disabled": this.disabled,
						"ui-output-content-reverse": this.reverse,
						"ui-output-content-truncate": this.truncate
					},
					ref: "output"
				}
				return this.$createElement("pre", data)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.ui-output-field::v-deep {
		&:not(.hide-dashed-border) {
			.v-input__slot {
				&:before, &:after {
					border-color: rgba(0, 0, 0, .42) !important;
					border-style: dashed !important;
				}
			}
		}
		&.hide-dashed-border {
			.v-input__slot {
				&:before, &:after {
					border-color: transparent !important;
					border-width: 0;
				}
			}
		}
		.v-input__slot {
			/*&:before, &:after {
				border-color: rgba(0, 0, 0, .42) !important;
				border-style: dashed !important;
			}*/
			.v-text-field__slot {
				max-width: 100%;
			}
			.ui-output-content {
				flex: 1 1 auto;
				line-height: 20px !important;
				padding: 8px 0 4px;
				max-width: 100%;
				min-width: 0;
				width: 100%;
				cursor: default;
				font-family: inherit;
				min-height: 32px;
				&.ui-output-content-truncate {
					overflow: hidden;
					text-overflow: ellipsis;
				}
				&.ui-output-textarea {
					padding: 0 !important;
					line-height: 1.75rem !important;
					min-height: 32px !important;
					white-space: break-spaces;
				}
				&.ui-output-content-disabled {
					font-style: italic;
				}
				&.ui-output-content-reverse {
					text-align: right;
				}
			}
		}
		&.v-input--dense {
			.ui-output-content {
				padding: 4px 0 2px;
				min-height: 26px;
			}
		}
	}
</style>