import Vue from "vue"
import { prettyPrintDurata } from "@/libs/utils"
import Nais from "@/libs/nais"

const VueNais = {
	install(Vue) {
		Object.defineProperty(Vue.prototype, "$nais", { get() { return Nais } })
		Vue.filter("durata", prettyPrintDurata)
	}
}

Vue.use(VueNais)
