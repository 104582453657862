<template lang="pug">
v-combobox(v-if="multiple", v-model="inputModel", :items="items", v-bind="$attrs", multiple, small-chips, hide-selected)
	template(#selection="{ attrs, item, parent, selected }")
		v-chip(v-bind="attrs", :input-value="selected", label, small, outlined)
			span(class="pr-2") {{item}}
			v-icon(small, @click="parent.selectItem(item)") $delete
v-text-field(v-else, v-model="inputModel", v-bind="$attrs")
</template>

<script>
export default {
	name: "ui-email-field",
	props: {
		value: { required: false },
		multiple: Boolean
	},
	data() {
		return {
			items: [],
			model: null	// può essere una stringa o un array di stringhe
		}
	},
	computed: {
		inputModel: {
			get() { return this.model },
			set(value) {
				this.model = value
				this.$emit("input", this.model)
			}
		}
	},
	methods: {
		updateItems(values) {
			if(!this.$isTruthy(values)) return
			const items = this.items.concat(values)
			this.items = items.filter((item, index) => items.indexOf(item) === index)
		}
	},
	watch: {
		value: {
			handler(value) {
				if(this.multiple) this.model = this.$isTruthy(value) ? [...value] : []
				else this.model = this.$isTruthy(value) ? value : ""
			},
			immediate: true
		},
		model: {
			handler(model) {
				if(this.multiple) this.updateItems(model)
			},
			immediate: true
		}
	}
}
</script>