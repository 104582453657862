<template lang="pug">
	div(class="w-100 d-flex flex-column")
		slot(name="title", v-bind="{ title: listTitle }")
			v-subheader(v-if="hasTitle") {{listTitle | capitalize}}
		slot(name="search")
			ui-search-field(v-model="search", :placeholder="searchPlaceholder", :counter="false", v-if="searchable")
		validation-provider(slim, :name="name", :rules="rules", #default="{ failed, errors }")
			slot(name="errors", v-bind="{ errors }", v-if="failed")
				p(class="error--text text-subtitle-2 font-weight-regular", v-for="error in errors") {{error}}
			v-data-iterator(v-model="selectedItems", :items="listItems", item-key="key", :footer-props="footerProps", v-bind="$attrs", :single-select="!multiple", :search="search", :selectable-key="selectableKey")
				template(#default="{ items, select, isSelected }")
					v-list(dense, class="primary--text")
						template(v-for="item in items")
							v-list-item(:input-value="isSelected(item)", @click="select(item, !isSelected(item))", :key="`item-${item.key}`", #default="{ active }", :disabled="!item[selectableKey]")
								v-list-item-action
									v-icon(v-if="!item[selectableKey]", small, disabled) fas fa-check
									v-icon(v-else-if="active", color="primary", small) {{selectedIcon}}
									v-icon(v-else, small) {{selectIcon}}
								v-list-item-content
									v-list-item-title(:class="{ 'black--text font-weight-bold': active }") {{item.text}}
							v-divider(v-if="item.divider", :key="`divider-${item.key}`")
				template(#footer.page-text="{ itemsLength }")
					slot(name="footer.page-text" v-bind="{ itemsLength }") su {{itemsLength}}
</template>

<script>
import I18nFormatterMixin from "@/mixins/formatter/i18n-formatter-mixin"
import FunctionFormatterMixin from "@/mixins/formatter/function-formatter-mixin"
import { getPropertyFromItem } from "vuetify/lib/util/helpers"

export default {
	name: "ui-select-list",
	mixins: [I18nFormatterMixin, FunctionFormatterMixin],
	inheritAttrs: false,
	model: { event: "change" },
	props: {
		value: { required: false },
		items: Array,
		itemValue: { type: [String, Array, Function], default: "value" },
		itemText: { type: [String, Array, Function], default: "text" },
		itemKey: [String, Array, Function],
		title: String,
		multiple: Boolean,
		searchable: Boolean,
		searchPlaceholder: { type: String, default: "ricerca" },
		name: String,
		rules: [String, Object],
		selectIcon: { type: String, default: "far fa-circle" },
		selectedIcon: { type: String, default: "far fa-check-circle" },
		selectable: { type: [Boolean, Function], default: true },
		selectableKey: { type: String, default: "selectable" }
	},
	data() {
		return {
			search: "",
			footerProps: {
				itemsPerPageText: "mostra",
				showFirstLastPage: true,
				nextIcon: "fas fa-angle-right", lastIcon: "fas fa-angle-double-right",
				prevIcon: "fas fa-angle-left", firstIcon: "fas fa-angle-double-left",
				itemsPerPageOptions: [5, 10, 20, -1],
				showCurrentPage: true
			}
		}
	},
	computed: {
		hasTitle() { return !!this.title },
		listTitle() {
			if(this.$te(this.title)) return this.$t(this.title)
			else return this.title
		},
		listItems() {
			return this._.map(this.items, (item, index) => {
				const value = getPropertyFromItem(item, this.itemValue, item)
				const text = this.getItemText(item)
				const key = this._.isNil(this.itemKey) ? index : getPropertyFromItem(item, this.itemKey)
				const divider =  index < this.items.length - 1
				return { value, text, key, divider, [this.selectableKey]: this.selectable === true || this.selectable(item, index) }
			})
		},
		needFormat() { return !this._.isNil(this.i18nPrefix) || !this._.isNil(this.format) },
		selectedItems: {
			get() {
				if(this.multiple) return this._.map(this.value, this.findItem)
				else if(!!this.value) return [this.findItem(this.value)]
				else return []
			},
			set(selected) {
				const values = this._.map(selected, "value")
				const updatedValue = this.multiple ? values : this._.head(values)
				this.$emit("change", updatedValue)
			}
		}
	},
	methods: {
		getItemText(item) {
			const text = getPropertyFromItem(item, this.itemText, item.toString())
			if(this.needFormat) return this.formatText(text)
			else return text
		},
		formatText(value) {
			if(!!value) {
				if(!this._.isNil(this.i18nPrefix)) return this.i18nFormat(value)
				else if(!this._.isNil(this.format)) return this.translate(value)
			}
			return value
		},
		findItem(value) {
			return this._.find(this.listItems, { value })
		}
	}
}
</script>