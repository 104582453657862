<template>
	<a :href="href" class="text-xs-right text-truncate" target="_blank">{{email}}</a>
</template>

<script>
	export default {
		name: "ui-email-link",
		props: {
			email: String
		},
		computed: {
			href() {
				if(!!this.email) return `mailto: ${this.email}`
				else return ""
			}
		}
	}
</script>