<template>
	<router-view/>
</template>

<script>
export default {
	name: "nais",
	data() {
		return {
			naisConfigSubscription: { id: "" },
			userInfoSubscription: { id: "" }
		}
	},
	computed: {
		naisConfigSubscribed() {
			const id = this._.get(this.naisConfigSubscription, "id")
			return this.$isTruthy(id)
		},
		userInfoSubscribed() {
			const id = this._.get(this.userInfoSubscription, "id")
			return this.$isTruthy(id)
		}
	},
	methods: {
		onWebsocketConnecting(connecting) { this.$store.commit("setConnecting", connecting) },
		onWebsocketConnected(websocket) {
			this.$store.commit("setConnected", websocket.connected)
			this.naisConfigSubscription = websocket.subscribe("/topic/nais-config", payload => {
				const config = this._.get(payload, "config", [])
				this._.each(config, item => this.$store.commit("setConfig", item))
			})
			this.userInfoSubscription = websocket.subscribeUser("/topic/info-utente", payload => { this.$store.commit("setUserInfo", payload) })
		}
	},
	created() {
		this.$eventBus.$on("websocket-connecting", this.onWebsocketConnecting)
		this.$eventBus.$on("websocket-connected", this.onWebsocketConnected)
	},
	beforeDestroy() {
		this.$eventBus.$off("websocket-connecting", this.onWebsocketConnecting)
		if(this.naisConfigSubscribed) {
			this.naisConfigSubscription.unsubscribe()
			this.naisConfigSubscription = { id: "" }
		}
		if(this.userInfoSubscribed) {
			this.userInfoSubscription.unsubscribe()
			this.userInfoSubscription = { id: "" }
		}
		this.$eventBus.$off("websocket-connected", this.onWebsocketConnected)
	}
}
</script>