<template lang="pug">
v-row(class="ui-pagination", justify="center", align="center")
	v-btn(small, color="primary", class="prev mb-0 mr-2", @click="goToFirstPage", :disabled="prevDisabled")
		v-icon fas fa-angle-double-left
	v-btn(small, color="primary", class="prev mb-0 mr-2", @click="goToPrevPage", :disabled="prevDisabled")
		v-icon fas fa-angle-left
	ui-select(v-model.number="currentPage", :items="totalPages", hide-details, class="mx-2 pt-0 pb-1 ui-page-select", :class="$vuetify.breakpoint.xsOnly && 'mobile'", :menu-props="{ minWidth: 150 }", :disabled="disabled")
		template(#selection="{ item }", v-if="$vuetify.breakpoint.xsOnly")
			span(class="v-select__selection--comma") {{item.value}}
	v-btn(small, color="primary", class="next mb-0 ml-2", @click="goToNextPage", :disabled="nextDisabled")
		v-icon fas fa-angle-right
	v-btn(small, color="primary", class="next mb-0 ml-2", @click="goToLastPage", :disabled="nextDisabled")
		v-icon fas fa-angle-double-right
</template>

<script>
export default {
	name: "ui-pagination",
	model: {
		prop: "page",
		event: "change"
	},
	props: {
		page: Number,
		pages: { type: Number, default: 0 },
		disabled: Boolean
	},
	data() {
		return {
			selectedPage: -1
		}
	},
	computed: {
		currentPage: {
			get() { return this.selectedPage },
			set(value) {
				this.selectedPage = value
				this.$emit("change", this.selectedPage)
			}
		},
		totalPages() {
			return this._.chain(this.pages).times(n => n + 1).map(page => ({ value: page, text: this.$t("default.pagination.page", { page, pages: this.pages }) })).value()
		},
		maxPage() {
			const maxPageItem = this._.maxBy(this.totalPages, "value")
			if (this._.isUndefined(maxPageItem)) return 1
			else return maxPageItem.value
		},
		prevDisabled() { return this.disabled || this.currentPage === 1 },
		nextDisabled() { return this.disabled || this.currentPage === this.maxPage }
	},
	methods: {
		goToFirstPage() { if(!this.prevDisabled) this.currentPage = 1 },
		goToPrevPage() { if(!this.prevDisabled) this.currentPage = this.currentPage - 1 },
		goToNextPage() { if(!this.nextDisabled) this.currentPage = this.currentPage + 1 },
		goToLastPage() { if(!this.nextDisabled) this.currentPage = this.maxPage }
	},
	watch: {
		page: {
			handler(value) { this.selectedPage = value || -1 },
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped>
.ui-pagination {
	font-size: 12px;
	.ui-page-select {
		flex: 0 1 0;
		&:not(.mobile) { min-width: 150px; }
		::v-deep .v-select {
			input { font-size: 12px; }
			.v-select__selections .v-select__selection--comma { font-size: 12px; }
		}
	}
	.prev, .next { min-width: 40px; }
}
</style>