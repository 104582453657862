<template lang="pug">
	v-container(fluid, fill-height)
		div(class="d-flex-column align-center justofy-center w-100")
			spinkit(type="wave")
			span(class="text-capitalize-first mt-2") {{loadingMessage}}
</template>

<script>
import Spinkit from "@/components/spinkit"
import { getTranslation } from "@/libs/i18n"

export default {
	name: "ui-page-loader",
	components: { Spinkit },
	props: {
		message: String,
		capitalize: Boolean
	},
	computed: {
		loadingMessage() {
			let message = this.$t("default.loading")
			if(this.$isTruthy(this.message)) message = getTranslation(this.message)
			if(this.capitalize) return this._.capitalize(message)
			else return message
		}
	}
}
</script>