import { render, staticRenderFns } from "./ui-table.vue?vue&type=template&id=08283434&scoped=true"
import script from "./ui-table.vue?vue&type=script&lang=js"
export * from "./ui-table.vue?vue&type=script&lang=js"
import style0 from "./ui-table.vue?vue&type=style&index=0&id=08283434&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08283434",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VCard,VProgressLinear})
