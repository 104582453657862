<template lang="pug">
v-container(fluid, ui-table-pagination)
	v-row(no-gutters, align="center", justify="space-between", :class="$vuetify.breakpoint.xsOnly && 'flex-column-reverse'")
		v-col(cols="12", sm="4", class="d-flex align-center", :class="$vuetify.breakpoint.xsOnly && 'justify-center mt-3'")
			span(class="text-capitalize") {{ $t('default.pagination.max.show') }}
			ui-field(type="select", v-model="maxItems", hide-details, :items="rowsPerPage", class="mx-2 pt-0 pb-1 ui-table-max", :menu-props="{ minWidth: 100 }")
			span {{ $t('default.pagination.max.of', { item: $tc(maxSuffix, total)}) }}
		v-col(cols="12" sm="8" class="d-flex align-center justify-end" :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end'")
			v-btn(small :color="color" :dark="!prevDisabled" class="prev mb-0 mr-2" @click="goToPrevPage" :disabled="prevDisabled")
				v-icon fas fa-angle-left
			span {{$t('default.pagination.currentPage', { page: page })}}
			v-btn(small :color="color" :dark="!nextDisabled" class="next mb-0 ml-2" @click="goToNextPage" :disabled="nextDisabled")
				v-icon fas fa-angle-right
</template>

<script>
import { DEFAULT_ROWS_PER_PAGE } from "@/components/table/ui-table-pagination"

export default {
	name: "ui-bigtable-pagination",
	props: {
		max: Number,
		page: Number,
		total: Number,
		color: String,
		maxSuffix: String
	},
	computed: {
		maxItems: {
			get() { return this.max },
			set(max) { this.updateMax(max) }
		},
		rowsPerPage() {
			const maxes = DEFAULT_ROWS_PER_PAGE
			if (this.max < this.total && !this._.includes(maxes, this.max)) maxes.push(this.max)
			return this._.sortBy(maxes)
		},
		pages() {
			const pages = this.total < this.max ? [1] : this._.times(Math.ceil(this.total / Math.abs(this.max)), n => n + 1)
			return this._.map(pages, page => ({ value: page, text: this.$t("default.pagination.page", { page, pages: pages.length }) }))
		},
		maxPage() {
			const maxPageItem = this._.maxBy(this.pages, "value")
			if (this._.isUndefined(maxPageItem)) return 1
			else return maxPageItem.value
		},
		prevDisabled() {
			return this.page === 1
		},
		nextDisabled() {
			return this.page === this.maxPage
		}
	},
	methods: {
		updateMax(max) {
			if(max !== this.max) {
				this.updatePagination(max, 1)
			}
		},
		updatePage(page, event) {
			if (page !== this.page) {
				this.updatePagination(this.max, page, event)
			}
		},
		updatePagination(max, page, event = null) {
			this.$emit("update:pagination", { page, max, event })
		},
		goToNextPage() {
			if(!this.nextDisabled) {
				this.updatePage(this.page + 1, 'next')
			}
		},
		goToPrevPage() {
			if(!this.prevDisabled) {
				this.updatePage(this.page - 1, 'prev')
			}
		},
	}
}
</script>