import Vue from "vue"
import anime from "animejs/lib/anime.es"

const VueAnime = {
	install(Vue) {
		Vue.anime = anime
		Object.defineProperty(Vue.prototype, "$anime", { get() { return anime } })
	}
}

Vue.use(VueAnime)