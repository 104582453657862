<template lang="pug">
	v-list(dense, class="pb-0")
		template(v-for="({ fileName, editable, downloadable }, index) in previews")
			v-list-item(:key="index", class="file-list-item")
				v-list-item-avatar
					ui-file-icon(:file-name="fileName")
				v-list-item-content {{fileName}}
				v-list-item-action(v-if="editable")
					div
						v-tooltip(top, v-if="clearable")
							template(#activator="{ on }")
								v-btn(icon, color="error", v-on="on", @click="removeFile(index)")
									v-icon fas fa-trash-alt
							| Rimuovi
							|
							b {{fileName}}
						v-tooltip(top, v-if="downloadable")
							template(#activator="{ on }")
								v-btn(icon, color="primary", v-on="on", @click="saveFile(index)")
									v-icon fas fa-download
							| Scarica
							|
							b {{fileName}}
			v-divider
</template>

<script>
import ModelMixin from "@/mixins/model-mixin"
import S3Mixin from "@/mixins/s3-mixin"
import UiFileIcon from "@/components/ui-file-icon"

export default {
	name: "ui-file-list",
	mixins: [ModelMixin, S3Mixin],
	components: { UiFileIcon },
	props: {
		clearable: Boolean
	},
	computed: {
		isMultiple() { return this._.isArray(this.model) },
		files() {
			if(this.isMultiple) return this.model
			else return [this.model]
		},
		previews() {
			return this._.map(this.files, file => {
				const fileName = this.getFileName(file)
				const downloadable = this.isS3File(file)
				const editable = this.clearable || downloadable
				return { fileName, editable, downloadable }
			})
		}
	},
	methods: {
		removeFile(index) {
			if(this.clearable) {
				if (this.isMultiple) this.model.splice(index, 1)
				else this.clear()
			}
		},
		saveFile(index) {
			const file = this.files[index]
			this.saveS3File(file.id)
		}
	}
}
</script>

<style lang="scss" scoped>
.file-list-item {
	max-height: 40px;
}
</style>
