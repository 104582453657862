import { uniqueId, find, isNil } from "lodash"

const notifiche = {
	state: {
		notifiche: []
	},
	getters: {
		notifiche: state => state.notifiche.filter(item => !item.read),
		hasNotifiche: (state, getters) => getters.notifiche.length > 0
	},
	mutations: {
		addNotifiche: (state, notifiche) => state.notifiche.push(...notifiche),
		addNotifica: (state, notifica) => state.notifiche.push({ id: uniqueId("notifica-"), read: false, notifica }),
		readNotifica: (state, id) => {
			const notifica = find(state.notifiche, { id })
			if(!isNil(notifica)) notifica.read = true
		},
		clear: state => state.notifiche = []
	}
}

export default notifiche