export default {
	methods: {
		hasRoles(...roles) {
			return this.$store.getters.hasAnyRoles(...roles)
		},
		hasGroup(...roles) {
			return this.$store.getters.hasAnyGroup(...roles)
		},
		hasParent(parent, ...roles) {
			return this.$store.getters.hasAnyParents(parent, ...roles)
		},
		hasNoParent(...roles) {
			return this.$store.getters.hasNoAnyParents(...roles)
		},
		agencyHasRole(role) {
			return this.$store.getters.agencyRoles.includes(role);
		},
		agencyHasRoles(...roles) {
			return roles.every(role => this.$store.getters.agencyRoles.includes(role));
		}
	}
}