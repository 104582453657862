import { getTranslation } from "@/libs/i18n"

export default {
	props: {
		label: String,
		name: String,
		placeholder: String,
		hint: String
	},
	computed: {
		labelI18n() {
			if(this.$isTruthy(this.label)) return getTranslation(this.label)
		},
		nameI18n() {
			if(this.$isTruthy(this.name)) return getTranslation(this.name)
		},
		placeholderI18n() {
			if(this.$isTruthy(this.placeholder)) return getTranslation(this.placeholder)
		},
		hintI18n() {
			if(this.$isTruthy(this.hint)) return getTranslation(this.hint)
		}
	}
}