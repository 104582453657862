<script>
	export default {
		name: "value-provider",
		computed: {
			provided() { return this.$attrs }
		},
		provide() { return this.provided },
		render() {
			return this.$scopedSlots.default(this.provided)
		}
	}
</script>