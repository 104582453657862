export default {
	methods: {
		$confirm(message, icon = "warning", actions = { ok: "default.confirm", ko: "default.cancel" }) {
			return new Promise(resolve => {
				const isAlreadyOpened = this.$store.getters.confirmOpen
				if(isAlreadyOpened) resolve(false)
				else this.$store.commit("showConfirm", { callback: resolve, message, icon, actions })
			})
		}
	}
}