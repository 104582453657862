<template>
    <v-card v-bind="$attrs">
        <div class="ui-table-container">
            <div class="d-none" ref="columns">
                <slot/>
            </div>
			<div class="ui-table-responsive">
            	<table class="ui-table" :class="tableClasses" :style="tableStyle">
					<caption v-if="$slots.caption">
						<slot name="caption"/>
					</caption>
					<colgroup>
						<col v-for="column in columns" :key="column.columnId" :name="column.columnId"/>
					</colgroup>
					<thead v-if="!hideHeaders">
						<slot name="header-row">
							<tr>
								<ui-table-header v-for="(column, index) in columns"
												 :key="`ui-table-header-${index}`"
												 :column="column"
												 :sort="sort"
												 :order="order"
												 @onSort="onSort"
												 :fixed-layout="fixedLayout"
								/>
							</tr>
						</slot>
						<slot name="append-headers"/>
						<tr v-if="lazyLoading" class="ui-table-progress">
							<slot name="progress">
								<th :colspan="columnsSpan">
									<v-progress-linear indeterminate :color="loadingColor" :height="10" class="ma-0"/>
								</th>
							</slot>
						</tr>
					</thead>
					<slot name="rows" v-bind="{ items: rows }">
						<tbody>
							<template v-for="row in tableRows">
								<slot name="row" v-bind="row">
									<tr :key="row.key" :class="getRowClass(row.item, row.key)">
										<template v-for="(column, index) in columns">
											<slot :name="column.cellSlotName" v-bind="{ item: row.item }">
												<ui-table-cell :column="column" :item="row.item" :row-key="row.key" :key="`cell-${row.key}-${index}`"/>
											</slot>
										</template>
									</tr>
									<tr v-if="hasExpansion && row.expanded" class="ui-table-expand-row">
										<td :colspan="columnsSpan" class="pa-1">
											<slot name="expand" v-bind="row"></slot>
										</td>
									</tr>
								</slot>
							</template>
						</tbody>
					</slot>
					<tr v-if="!tableRows.length" class="no-items">
						<td :colspan="columnsSpan">
							<slot name="loadingText" v-if="lazyLoading">
								<span class="d-block px-2 text-center">{{ $t(loadingText) }}</span>
							</slot>
							<slot name="noItems" v-else>
								<span class="d-block px-2 text-center" v-html="$t(noItemsText)"/>
							</slot>
						</td>
					</tr>
				</table>
			</div>
			<slot name="pagination" v-bind="{ max, page, total: getTotalItems(), onPage, color: paginationColor, maxSuffix: paginationMaxSuffix, ...getOthersPaginationBindings() }">
            	<ui-table-pagination v-if="paginationEnabled" :max="max" :page="page" :total-items="getTotalItems()" @update:pagination="onPage" :color="paginationColor" :max-suffix="paginationMaxSuffix"/>
			</slot>
        </div>
    </v-card>
</template>

<script>
import UiTableCell from "./ui-table-cell"
import UiTableHeader from "./ui-table-header"
import UiTablePagination from "./ui-table-pagination"
import Themeable from "vuetify/lib/mixins/themeable"
import LoadingMixin from "@/mixins/loading-mixin"
import { isString } from "lodash"

const NO_ORDER = process.env.VUE_APP_NO_ORDER
const ASC_ORDER = process.env.VUE_APP_ASC_ORDER
const DESC_ORDER = process.env.VUE_APP_DESC_ORDER
const DEFAULT_MAX = +process.env.VUE_APP_DEFAULT_MAX
const DEFAULT_PAGE = +process.env.VUE_APP_DEFAULT_PAGE

export default {
	name: "ui-table",
	components: { UiTableCell, UiTableHeader, UiTablePagination },
	mixins: [Themeable, LoadingMixin],
	inheritAttrs: false,
	props: {
		items: {type: Array, default: () => ([]) },
		itemsLength: Number,
		hideHeaders: Boolean,
		rowClass: [String, Function],
		noItemsText: { type: String, default: "default.no-item" },
		loadingText: { type: String, default: "default.loading" },
		bordered: Boolean,
		striped: Boolean,
		dense: Boolean,
		hoverable: { type: Boolean, default: true },
		pagination: {
			type: [Boolean, String],
			default: false,
			validator: value => {
				if (isString(value)) return ["router", "default"].indexOf(value) !== -1
				return true
			}
		},
		singleExpand: Boolean,
		expandDisabled: Boolean,
		itemKey: [String, Function],
		fixedLayout: Boolean,
		tableStyle: [String, Object],
		defaultSort: [String, Object],
		defaultMax: Number,
		multisort: Boolean,
		loadingColor: { type: String, default: "primary" },
		paginationColor: { type: String, default: "primary" },
		paginationMaxSuffix: { type: String, default: "default.pagination.max.item"}
	},
	data() {
		return {
			columns: [],
			rows: [],
			page: DEFAULT_PAGE,
			max: DEFAULT_MAX,
			sort: "",
			order: NO_ORDER,
			expansionTracker: null
		}
	},
	computed: {
		tableId() { return this._.uniqueId("ui-table-") },
		tableClasses() {
			return {
				bordered: this.bordered,
				striped: this.striped,
				dense: this.dense,
				hoverable: this.hoverable,
				"fixed-layout": this.fixedLayout,
				"table-loading": this.lazyLoading,
				"table-paginated": this.paginationEnabled,
				...this.themeClasses
			}
		},
		paginationEnabled() { return !!this.pagination },
		routerPagination() { return this.paginationEnabled && this.pagination === "router" },
		paginationParams() {
			const query = { page: this.page, max: this.max }
			if (!!this.sort) {
				query.sort = this.sort
				query.order = this.order || NO_ORDER
			}
			return query
		},
		tableRows() {
			return this.rows.map((item, index) => {
				const row = {item}
				if (!!this.itemKey) {
					if (this._.isString(this.itemKey)) row.key = this._.get(item, this.itemKey)
					else row.key = this.itemKey(item)
				} else row.key = index
				if (this.hasExpansion) row.expanded = this.isExpanded(row.key)
				return row
			})
		},
		hasExpansion() {
			return !this.expandDisabled && !!this.$scopedSlots.expand
		},
		columnsSpan() {
			return this._.reduce(this.columns, (total, column) => {
				const span = column.getColspan() || 1
				return total + span
			}, 0)
		}
	},
	methods: {
		getColumnIndex(column) {
			return [].indexOf.call(this.$refs.columns.children, column.$el)
		},
		addColumn(column) {
			const index = this.getColumnIndex(column)
			column.columnIndex = index
			console.debug("ui-table add column", column.columnId, "at index", index)
			this.columns.splice(index, 0, column)
		},
		removeColumn(column) {
			const index = column.columnIndex
			const realIndex = this._.findIndex(this.columns, column => column.columnIndex === index)
			if(realIndex >= 0) {
				console.debug("ui-table remove column", column.columnId, "at index", realIndex)
				this.columns.splice(realIndex, 1)
			}
		},
		getRowClass(row) {
			if (!this._.isNil(this.rowClass)) {
				if (this._.isFunction(this.rowClass)) return this.rowClass(row)
				else return this.rowClass
			}
		},
		findItemIndex(rowKey) {
			if (!!this.itemKey) {
				if (this._.isString(this.itemKey)) return this._.findIndex(this.rows, {[this.itemKey]: rowKey})
				else return this._.findIndex(this.rows, item => rowKey === this.itemKey(item))
			} else return rowKey
		},
		updateItem(item, rowKey) {
			const index = this.findItemIndex(rowKey)
			console.debug("ui-table update item", item, rowKey, index)
			if (index !== -1) this.rows.splice(index, 1, item)
		},
		getExpansionKey(index) {
			return `extension-${index}`
		},
		isExpanded(index) {
			if (this.hasExpansion) {
				if (this.singleExpand) return this.expansionTracker === this.getExpansionKey(index)
				else return this.expansionTracker[this.getExpansionKey(index)] || false
			}
			return false
		},
		toggleExpansion(index) {
			console.debug("ui-table toggle expansion", this.isExpanded(index))
			if (this.hasExpansion) {
				if (this.isExpanded(index)) {
					if (this.singleExpand) this.expansionTracker = null
					else this.$set(this.expansionTracker, this.getExpansionKey(index), false)
				} else {
					if (this.singleExpand) this.expansionTracker = this.getExpansionKey(index)
					else this.$set(this.expansionTracker, this.getExpansionKey(index), true)
				}
			}
		},
		getTotalItems() {
			if(!this._.isNil(this.itemsLength)) return this.itemsLength
			return this.items.length
		},
		setDefaultSort() {
			if (this._.isPlainObject(this.defaultSort)) {
				this.sort = this.defaultSort.sort || ""
				this.order = this.defaultSort.order || NO_ORDER
			} else {
				this.sort = this.defaultSort || ""
				this.order = NO_ORDER
			}
		},
		setDefaultMax(max) {
			if(this.$isTruthy(max)) this.max = max
		},
		onSort(props) {
			console.debug("ui-table on sort", props)
			if (props.order) {
				this.sort = props.prop
				this.order = props.order
			} else this.setDefaultSort()
			this.updatePagination()
		},
		onPage(props) {
			console.debug("ui-table on page", props)
			this.page = props.page
			this.max = props.max
			this.updatePagination()
		},
		updatePagination() {
			const query = this.paginationParams
			if (this.routerPagination) {
				console.debug("ui-table update route pagination", query)
				this.$router.push({query: query})
			} else {
				console.debug("ui-table update pagination", query)
				this.doPagination()
			}
			this.$emit("update:pagination", query)
		},
		doPagination() {
			console.debug("ui-table do pagination")
			this.lazyLoading = true
			this.rows = []
			const items = this.items || []
			let pipeline = this._.chain([...items])
			if (!!this.sort) {
				console.debug("ui-table sort by", this.sort, this.order)
				pipeline = pipeline.sortBy([item => this._.get(item, this.sort)])
				if (this.order === DESC_ORDER) pipeline = pipeline.reverse()
			}
			if (this.paginationEnabled) {
				const start = this.max * (this.page - 1)
				const end = start + this.max
				console.debug("ui-table slice rows", start, end)
				pipeline = pipeline.slice(start, end)
			}
			this.rows = pipeline.value()
			this.lazyLoading = false
		},
		onRouteChange() {
			if (this.routerPagination) {
				const pagination = this.$route.query
				console.debug("ui-table on route change", pagination)
				if (!_.isNil(pagination.max)) {
					const max = +pagination.max
					if (max !== this.max) this.max = max
				} else this.max = DEFAULT_MAX
				if (!_.isNil(pagination.page)) {
					const page = +pagination.page
					if (page !== this.page) this.page = page
				} else this.page = DEFAULT_PAGE
				if (!_.isNil(pagination.sort)) {
					const sort = pagination.sort
					if (sort !== this.sort) this.sort = sort
				} else this.sort = ""
				if (!_.isNil(pagination.order)) {
					const order = pagination.order
					if (order !== this.order) this.order = order
				} else this.order = NO_ORDER
				this.doPagination()
			}
		},
		getOthersPaginationBindings() {
			return {}
		}
	},
	watch: {
		$route: {
			handler: "onRouteChange",
			immediate: true
		},
		singleExpand: {
			handler(flag) {
				if (flag) this.expansionTracker = ""
				else this.expansionTracker = {}
			},
			immediate: true
		},
		items: "doPagination",
		defaultSort: {
			handler: "setDefaultSort",
			immediate: true
		},
		defaultMax: {
			handler: "setDefaultMax",
			immediate: true
		}
	},
	mounted() {
		if (!this.routerPagination) this.doPagination()
	}
}
</script>

<style lang="scss" scoped>
	@import "@/assets/style/ui-table";
</style>