<template>
	<v-icon v-bind="$attrs">{{icon}}</v-icon>
</template>

<script>
import fileExtension from "file-extension"

export default {
	name: "ui-file-icon",
	props: {
		fileName: String
	},
	computed: {
		icon() {
			const extension = !!this.fileName ? fileExtension(this.fileName) : ""
			switch(extension) {
				case "ai":
				case "bmp":
				case "gif":
				case "ico":
				case "jpeg":
				case "jpg":
				case "png":
				case "ps":
				case "psd":
				case "svg":
				case "tif":
				case "tiff": return "far fa-file-image"
				case "doc":
				case "docx": return "far fa-file-word"
				case "odt":
				case "rtf":
				case "tex":
				case "txt":
				case "wks":
				case "wps":
				case "wpd": return "far fa-file-alt"
				case "7z":
				case "arj":
				case "deb":
				case "pkg":
				case "rar":
				case "rpm":
				case "tar.gz":
				case "z":
				case "zip": return "far fa-file-archive"
				case "ods":
				case "xlr":
				case "xls":
				case "xlsx": return "far fa-file-excel"
				case "pdf": return "far fa-file-pdf"
				case "key":
				case "odp":
				case "pps":
				case "ppt":
				case "pptx": return "far fa-file-powerpoint"
				default: return "far fa-file"
			}
		}
	}
}
</script>
