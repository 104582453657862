import Vue from "vue"
import naisWebsocket from "@/libs/websocket"

const VueNaisWebsocket = {
	install(Vue) {
		Vue.$websocket = naisWebsocket
		Object.defineProperty(Vue.prototype, "$websocket", { get() { return naisWebsocket } })
	}
}

Vue.use(VueNaisWebsocket)