import i18n from "@/plugins/i18n"
import { isString, isPlainObject, isNil, sortBy, isArray, forEach, map, join, get, isEmpty, chain, findIndex } from "lodash"
import store from "@/store"

export function isTruthy(value) {
	if(isNil(value)) return false
	if(isString(value) || isArray(value)) return !isEmpty(value)
	return true
}

export function base64ToBlob(base64, contentType, sliceSize = 512) {
	const byteCharacters = atob(base64)
	const byteArrays = []
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize)
		const byteNumbers = new Array(slice.length)
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i)
		}
		const byteArray = new Uint8Array(byteNumbers)
		byteArrays.push(byteArray)
	}
	return new Blob(byteArrays, { type: contentType })
}

export function base64ToFile(base64, fileName, contentType, sliceSize = 512) {
	const blob = base64ToBlob(base64, contentType, sliceSize)
	return new File([blob], fileName, { type: contentType })
}

export function fileToBase64(file, url = false) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = event => {
			const raw = event.target.result
			const base64 = url ? raw : raw.split(",")[1]
			resolve(base64)
		}
		reader.onerror = error => reject(error)
		reader.readAsDataURL(file)
	})
}

export function fileToUint8Array(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = event => {
			const raw = event.target.result
			const typedArray = new Uint8Array(raw)
			resolve(typedArray)
		}
		reader.onerror = error => reject(error)
		reader.readAsArrayBuffer(file)
	})
}

export async function fileToByteArray(file) {
	const byteArray = []
	const u8Array = await fileToUint8Array(file)
	for(let i = 0; i < u8Array.length; i++) byteArray.push(u8Array[i])
	return byteArray
}

export function fileToText(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = event => {
			const content = event.target.result
			resolve(content)
		}
		reader.onerror = error => reject(error)
		reader.readAsText(file)
	})
}

export async function fileToJson(file) {
	const content = await fileToText(file)
	return JSON.parse(content)
}

export async function encodeFile(file, encoding) {
	if(isNil(file)) return null
	if(isS3File(file)) return file
	if(isNil(encoding)) encoding = "file"
	switch(encoding.toLowerCase()) {
		case "base64": return { fileName: file.name, contentType: file.type, content: await fileToBase64(file) }
		case "array": return { fileName: file.name, contentType: file.type, content: await fileToByteArray(file) }
		default: return file
	}
}

export function isS3File(file) {
	const id = get(file, "id")
	return !isNil(id)
}

export function parseApi(api) {
	if(isString(api)) return { method: "get", url: api }
	else if(isPlainObject(api)) return Object.assign({}, { method: "get" }, api)
	else return {}
}

export function prettyPrintDurata(durata) {
	if(isNil(durata)) return ""
	if (!!durata.giorni) return `${durata.giorni} giorn${durata.giorni > 1 ? "i" : "o"}`
	if (!!durata.mesi) return `${durata.mesi} mes${durata.mesi > 1 ? "i" : "e"}`
	if (!!durata.anni) return `${durata.anni} ann${durata.anni > 1 ? "i" : "o"}`
	return "durata non valida"
}

export function prettyPrintDurate(durate) {
	if(!isNil(durate)) {
		const durateReali = isArray(durate) ? durate : get(durate, "durate", [])
		if(durateReali.length > 0) return join(map(durateReali, durata => prettyPrintDurata(durata)), ", ")
	}
	return ""
}

export function sortDurate(durate) { return sortBy(durate, [d => d.giorni, d => d.mesi, d => d.anni]) }

export function compareDurate(a, b) {
	if(isNil(a) && isNil(b)) return 0
	if(isNil(b)) return 1
	if(isNil(a)) return -1
	if(!isNil(a.giorni) && !isNil(b.giorni)) {
		if(a.giorni < b.giorni) return -1
		else if(a.giorni > b.giorni) return 1
	}
	if(!isNil(a.mesi) && !isNil(b.mesi)) {
		if(a.mesi < b.mesi) return -1
		else if(a.mesi > b.mesi) return 1
	}
	if(!isNil(a.anni) && !isNil(b.anni)) {
		if(a.anni < b.anni) return -1
		else if(a.anni > b.anni) return 1
	}
	return 0
}

export function hasDurata(durate, durata) {
	if(!isTruthy(durate)) return false
	const index = findIndex(durate, d => compareDurate(d, durata) === 0)
	return index >= 0
}

export function jsonToFormData(json, ignored) {
	const formData = new FormData()
	function append(data, path) {
		if(!ignore(path)) {
			path = path || ""
			if(data instanceof File) formData.append(path, data)
			else if(isArray(data)) forEach(data, (item, index) => append(item, `${path}[${index}]`))
			else if(typeof data === "object" && data) forEach(data, (item, key) => {
				if(data.hasOwnProperty(key)) {
					if(path === "") append(item, key)
					else append(item, `${path}.${key}`)
				}
			})
			else if(!isNil(data)) formData.append(path, data)
		}
	}
	function ignore(path) { return isArray(ignored) && ignored.some(function(x) { return x === path }) }
	append(json)
	return formData
}

export function localeToBcp47(locale) {
	switch(locale) {
		case "it": return "it-it"
		case "en": return "en-GB"
		case "de": return "de-DE"
		case "fr": return "fr-FR"
		case "es": return "es-ES"
		default: throw new Error(`unrecognized locale: ${locale}`)
	}
}

export function formatBoolean(value) {
	if(value === true) return i18n.t("const.misc.si")
	else return i18n.t("const.misc.no")
}

export function handleError(error, defaultMessage) {
	const status = error.status
	let message = get(error, "data.error_description", i18n.t(defaultMessage))
	if(status === 422) message = chain(error.data).values().join("</br>").value()
	store.commit("setException", message)
}

export const VueUtils = {
	install(Vue) {
		Vue.$isTruthy = isTruthy
		Object.defineProperty(Vue.prototype, "$isTruthy", { get() { return isTruthy } })
		Vue.filter("boolean", formatBoolean)
	}
}