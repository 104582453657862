export default {
	props: {
		loading: { type: Boolean, default: false }
	},
	data() {
		return {
			internalLoading: false
		}
	},
	computed: {
		lazyLoading: {
			get() { return this.internalLoading },
			set(value) {
				this.internalLoading = value
				this.$emit("update:loading", this.internalLoading)
			}
		}
	},
	watch: {
		loading: {
			handler(value) { this.internalLoading = !!value },
			immediate: true
		}
	}
}