import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import security from "./security-module"
import user from "./user-module"
import app from "./app-module"
import notifiche from "./notifiche-module"
import websocket from "./websocket-module"

Vue.use(Vuex)

export default new Vuex.Store({
	modules: { security, user, app, notifiche, websocket },
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
			key: "vuex-nais"
		}),
		createPersistedState({
			paths: ["app.version"],
			storage: window.localStorage,
			key: "vuex-nais-version"
		})
	]
})
