<script>
	import RenderHelperMixin from "@/mixins/render-helper-mixin"
	import { modelMixinFactory } from "@/mixins/model-mixin"
	import FieldLocalizedMixin from "@/mixins/field-localized-mixin"
	import { VTextarea, VCheckbox, VRadioGroup, VSwitch, VSlider, VRangeSlider, VTextField } from "vuetify/lib"

	const validTypes = ["text", "textarea", "select", "remote-select", "autocomplete", "combo", "checkbox", "radio", "switch", "slider", "password", "date", "datetime", "number", "file", "range", "image", "table", "minmax", "email", "period"]

	export function validateTipoField(type) { return validTypes.indexOf(type) !== -1 }

	export default {
		name: "ui-field",
		inheritAttrs: false,
		components: { VTextarea, VCheckbox, VRadioGroup, VSwitch, VSlider, VRangeSlider, VTextField },
		mixins: [RenderHelperMixin, modelMixinFactory({ required: false }, "model", 0, "fieldValue"), FieldLocalizedMixin],
		props: {
			bails: { type: Boolean, default: true },
			debounce: Number,
			immediate: Boolean,
			mode: String,
			rules: [String, Object],
			skipIfEmpty: { type: Boolean, default: true },
			vid: String,
			type: { type: String, default: "text", validator: validateTipoField },
			large: { type: Boolean, default: false },
			readonly: Boolean,
			uppercase: Boolean,
			lowercase: Boolean,
			capitalize: Boolean,
			multiple: Boolean,
			clearable: Boolean,
			validationDisabled: Boolean,
			hideErrorMessages: Boolean
		},
		computed: {
			isClearable() { return this.clearable },
			isMultiple() { return this.multiple },
			component() {
				if(this.readonly && !this.selfHandleReadonly) return "ui-output-field"
				switch(this.type) {
					case "textarea": return "v-textarea"
					case "remote-select": return "ui-remote-select"
					case "autocomplete":
					case "combo":
					case "select": return "ui-select"
					case "checkbox": return "v-checkbox"
					case "radio": return "v-radio-group"
					case "switch": return "v-switch"
					case "slider": return "v-slider"
					case "range": return "v-range-slider"
					case "date": return "ui-date-field"
					case "datetime": return "ui-datetime-field"
					case "file": return "ui-file-field"
					case "image": return "ui-image-field"
					case "table": return "ui-edit-table"
					case "minmax": return "ui-minmax-decimal-field"
					case "email": return "ui-email-field"
					case "period": return "ui-period-field"
					default: return "v-text-field"
				}
			},
			selfHandleReadonly() { return this.type === "minmax" || this.type === "date" || this.type === "period" || this.type === "datetime" },
			fieldType() {
				switch(this.type) {
					case "text":
					case "password":
					case "number": return this.type
				}
			},
			inputProp() {
				switch(this.type) {
					case "checkbox":
					case "radio":
					case "switch": return "input-value"
					default: return "value"
				}
			},
			inputEvent() {
				switch(this.type) {
					case "checkbox":
					case "radio":
					case "period":
					case "switch": return "change"
					default: return "input"
				}
			},
			listeners() {
				const listeners = {}
				if(this.inputEvent !== "input") listeners[this.inputEvent] = this.input
				if(this.clearable && !this._.startsWith(this.component, "ui-")) this._.merge(listeners, this.clearEvent)
				return Object.assign({}, this.$listeners, listeners)
			},
			attrs() {
				const attrs = { multiple: this.isMultiple }
				if(this.isClearable && !this.readonly) {
					if(this._.startsWith(this.component, "ui-")) attrs.clearable = this.isClearable
					else if(this.hasValue) this._.merge(attrs, this.clearButton)
				}
				return Object.assign({}, this.$attrs, attrs)
			}
		},
		methods: {
			async validate() {
				if(!this._.isNil(arguments[0])) return await this.$refs.validator.validate(arguments[0])
				else return await this.$refs.validator.validate()
			}
		},
		render(h) {
			const data = {
				props: {
					bails: this.bails,
					debounce: this.debounce,
					immediate: this.immediate,
					mode: this.mode,
					name: this.nameI18n,
					rules: this.rules,
					skipIfEmpty: this.skipIfEmpty,
					vid: this.vid,
					tag: "div",
					slim: true,
					disabled: this.validationDisabled
				},
				ref: "validator",
				scopedSlots: {
					default: ({ errors, required }) => {
						const attrs = {
							[this.inputProp]: this.fieldValue,
							errorCount: Infinity,
							dense: !this.large,
							label: this.labelI18n,
							placeholder: this.placeholderI18n,
              				hint: this.hintI18n
						}
						if(!this.hideErrorMessages) attrs.errorMessages = errors
						else attrs.error = this.$isTruthy(errors)
						if(!this._.isNil(this.fieldType)) attrs.type = this.fieldType
						if (this.type === "autocomplete") attrs.search = true
						else if (this.type === "combo") attrs.combo = true
						else if(this.type === "textarea" && this.readonly) attrs.textarea = true
						if(this.readonly && this.selfHandleReadonly) attrs.readonly = this.readonly
						const data = {
							staticClass: "ui-field",
							class: {
								required: required && !this.readonly,
								uppercase: this.uppercase,
								lowercase: this.lowercase,
								capitalize: this.capitalize
							},
							on: this.listeners,
							attrs: Object.assign({}, this.attrs, attrs),
							ref: "inputComponent"
						}
						return this.renderComponentWithSlots(h, this.component, data)
					}
				}
			}
			return h("validation-provider", data)
		}
	}
</script>

<style lang="scss">
	.ui-field {
		&.uppercase { input, .ui-output-content { text-transform: uppercase; } }
		&.lowercase { input, .ui-output-content { text-transform: lowercase; } }
		&.capitalize { input, .ui-output-content { text-transform: capitalize; } }
		&.error--text .v-input__slot {
			input, textarea {
				color: var(--v-error-base) !important;
				caret-color: var(--v-error-base) !important;
			}
		}
	}
</style>