<template>
	<div class="ui-image-field w-100">
		<ui-file-field accept="image/*" icon="far fa-file-image" v-bind="fileAttrs" v-on="fileListeners">
			<template #activator="slot">
				<slot name="activator" v-bind="slot"/>
			</template>
			<template #preview v-if="$vuetify.breakpoint.lgAndUp">
				<ui-image-gallery v-model="model" :clearable="clearable" :image-props="imageProps"/>
			</template>
		</ui-file-field>
		<v-dialog persistent v-model="showCropper" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly" v-if="cropAllowed">
			<v-card>
				<v-toolbar color="primary">
					<v-toolbar-title class="white--text">{{$t('fields.ui-image-field.crop') | capitalize}} <i>{{image.fileName}}</i></v-toolbar-title>
				</v-toolbar>
				<v-card-text class="pt-5">
					<cropper ref="cropper" :src="src" :stencil-props="{ aspectRatio: 1 }" stencilComponent="circle-stencil"/>
				</v-card-text>
				<v-card-actions>
					<v-btn color="secondary" @click="close">{{$t('default.cancel')}}</v-btn>
					<v-spacer/>
					<v-btn color="primary" @click="crop">{{$t('default.confirm')}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import ModelMixin from "@/mixins/model-mixin"
	import UiFileField from "./ui-file-field"
	import UiImageGallery from "./ui-image-gallery"
	import { Cropper, CircleStencil } from "vue-advanced-cropper"
	import { base64ToFile, fileToBase64 } from "@/libs/utils"

	export default {
		name: "ui-image-field",
		mixins: [ModelMixin],
		inheritAttrs: false,
		components: { UiFileField, UiImageGallery, Cropper, CircleStencil },
		props: {
			multiple: Boolean,
			clearable: Boolean,
			allowCrop: Boolean,
			imageProps: Object
		},
		data() {
			return {
				showCropper: false,
				image: {},
				loadingPreview: false
			}
		},
		computed: {
			isClearable() { return this.clearable },
			isMultiple() { return this.multiple },
			src() { if(!this._.isEmpty(this.image)) return this.image.content },
			fileAttrs() { return Object.assign({ multiple: this.isMultiple, clearable: this.isClearable, value: this.model }, this.$attrs) },
			fileListeners() { return Object.assign({}, this.$listeners, { input: this.setImage }) },
			cropAllowed() { return this.allowCrop && !this.isMultiple }
		},
		methods: {
			async setImage(file) {
				console.debug("ui-image-field set image", file)
				if(this.cropAllowed) {
					this.image = await this.parseImage(file)
					this.open()
				} else this.model = file
			},
			parseImage(file) {
				return new Promise(resolve => {
					const reader = new FileReader()
					reader.onload = event => {
						const parsed = {
							fileName: file.name,
							type: file.type,
							content: event.target.result,
							size: file.size / 1000
						}
						console.debug("parsed image", parsed)
						resolve(parsed)
					}
					reader.onerror = error => {
						console.error("file reader error", error)
						resolve({})
					}
					reader.readAsDataURL(file)
				})
			},
			open() {
				this.showCropper = true
			},
			crop() {
				const { canvas } = this.$refs.cropper.getResult()
				const image = canvas.toDataURL()
				const base64 = image.split(",")[1]
				this.model = base64ToFile(base64, this.image.fileName, this.image.type)
				this.close()
			},
			close() {
				this.showCropper = false
				this.image = {}
			}
		}
	}
</script>