<script>
	import RenderHelperMixin from "@/mixins/render-helper-mixin"

	export default {
		name: "ui-password-field",
		mixins: [RenderHelperMixin],
		model: {
			prop: "fieldValue"
		},
		props: {
			name: { type: String, default: "password" },
			label: { type: String, default: "default.password" },
			hideShowPassword: Boolean,
			utente: Object
		},
		data() {
			return {
				showPassword: false
			}
		},
		computed: {
			showIcon() { return this.showPassword ? "fas fa-eye-slash" : "fas fa-eye" }
		},
		methods: {
			toggleShowPassword() { this.showPassword = !this.showPassword }
		},
		render(h) {
			const attrs = {
				type: "text",
				name: this.name,
				label: this.label,
				clearable: false,
				counter: 50
			}
			const rules = { min: 5, max: 50, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*,.\-_]*)(?=.{5,})/ }
			if(!this._.isNil(this.utente)) {
				rules.is_not = this.utente.username
				if(this._.isNil(this.utente.id)) rules.required = true
			}
			const listeners = { copy(e) { e.preventDefault() } }
			if(!this.hideShowPassword) {
				attrs.appendOuterIcon = this.showIcon
				listeners["click:append-outer"] = this.toggleShowPassword
			}
			const data = {
				class: { "obfusched": !this.showPassword },
				on: Object.assign({}, this.$listeners, listeners),
				attrs: Object.assign({}, this.$attrs, attrs, { rules })
			}
			return this.renderComponentWithSlots(h, "ui-field", data)
		}
	}
</script>