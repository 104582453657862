<template>
	<v-container fluid class="ui-image-gallery">
		<v-row dense>
			<v-col cols="12" v-bind="colSize" v-for="({ content, fileName, editable, downloadable, key }, index) in previews" :key="key">
				<v-hover #default="{ hover }">
					<v-img :src="content" v-bind="imageProps">
						<template #placeholder>
							<v-row class="fill-height" align="center" justify="center">
								<v-progress-circular indeterminate size="150" width="10" color="grey lighten-1">Carico {{fileName}}...</v-progress-circular>
							</v-row>
						</template>
						<v-fade-transition>
							<v-overlay absolute v-if="editable && hover" color="white">
								<v-row>
									<v-col v-if="clearable">
										<v-tooltip top>
											<template #activator="{ on }">
												<v-btn fab color="error" v-on="on" @click="removeImage(index)">
													<v-icon>fas fa-trash-alt</v-icon>
												</v-btn>
											</template>
											Rimuovi {{fileName}}
										</v-tooltip>
									</v-col>
									<v-col v-if="downloadable">
										<v-tooltip top>
											<template #activator="{ on }">
												<v-btn fab color="primary" v-on="on" @click="saveImage(index)">
													<v-icon>fas fa-download</v-icon>
												</v-btn>
											</template>
											Scarica {{fileName}}
										</v-tooltip>
									</v-col>
								</v-row>
							</v-overlay>
						</v-fade-transition>
					</v-img>
				</v-hover>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ModelMixin from "@/mixins/model-mixin"
import S3Mixin from "@/mixins/s3-mixin"
import { fileToBase64 } from "@/libs/utils"

export default {
	name: "ui-image-gallery",
	mixins: [ModelMixin, S3Mixin],
	props: {
		clearable: Boolean,
		imageProps: Object
	},
	data() {
		return {
			loading: false,
			previews: []
		}
	},
	computed: {
		isMultiple() { return this._.isArray(this.model) },
		images() {
			if(this.isMultiple) return this.model
			else if(!this._.isNil(this.model)) return [this.model]
		},
		colSize() {
			if(this.isMultiple) return { sm: 6, md: 4, lg: 3, xl: 2 }
			else return {}
		}
	},
	methods: {
		async loadPreviews(images) {
			if(this._.isNil(images)) this.previews = []
			else try {
				this.loading = true
				this.previews = await Promise.all(this._.map(images, async image => {
					const content = await this.getContent(image)
					const fileName = this.getFileName(image)
					const downloadable = this.isS3File(image)
					const editable = this.clearable || downloadable
					const key = this._.uniqueId("preview-")
					return { content, fileName, editable, downloadable, key }
				}))
			} finally { this.loading = false }
		},
		async getContent(image) {
			if(this.isS3File(image)) return await this.downloadS3File(image, true)
			else return await fileToBase64(image, true)
		},
		removeImage(index) {
			if(this.isMultiple) this.model.splice(index, 1)
			else this.clear()
		},
		saveImage(index) {
			const image = this.images[index]
			this.saveS3File(image.id)
		}
	},
	watch: {
		images: {
			handler: "loadPreviews",
			immediate: true
		}
	}
}
</script>