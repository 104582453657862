import I18nFormatterMixin from "./formatter/i18n-formatter-mixin"
import FunctionFormatterMixin from "./formatter/function-formatter-mixin"
import RemoteFormatterMixin from "./formatter/remote-formatter-mixin"

export function valueFormatterMixinFactory(i18n = true, fn = true, remote = true) {
	const mixins = []
	if(i18n) mixins.push(I18nFormatterMixin)
	if(fn) mixins.push(FunctionFormatterMixin)
	if(remote) mixins.push(RemoteFormatterMixin)
	return {
		mixins,
		props: {
			value: { required: false }
		},
		data() {
			return {
				formattedValue: ""
			}
		},
		methods: {
			async formatValue(value) {
				if(this.$isTruthy(value)) {
					if (i18n && !this._.isNil(this.i18nPrefix)) this.formattedValue = this.i18nTranslate(value)
					else if(fn && !this._.isNil(this.format)) this.formattedValue = this.translate(value)
					else if (remote && !this._.isNil(this.formatApi)) this.formattedValue = await this.remoteTranslate(value)
					else this.formattedValue = value
				} else this.formattedValue = ""
			}
		},
		watch: {
			value: {
				handler: "formatValue",
				immediate: true
			}
		}
	}
}

export default valueFormatterMixinFactory()
