<template lang="pug">
div(class="ui-date-field w-100 p-relative")
	ui-output-field(:value="dateFormatted", v-if="readonly", :prepend-icon="calendarIcon", v-bind="$attrs", :label="labelI18n", :placeholder="placeholderI18n")
	v-menu(v-model="showPicker", :close-on-content-click="false", transition="slide-y-transition", offset-y, max-width="290px", min-width="290px", v-else)
		template(#activator="{ on }")
			v-text-field(:value="dateFormatted", @change="dateFormatted = $event", :prepend-icon="calendarIcon", v-bind="textAttrs", v-on="{ ...on, ...clearEvent }", hint="Formato DD-MM-YYYY", :color="color")
		v-date-picker(v-model="date", :locale="locale", :color="color", :multiple="multiple", :range="range", :no-title="!showTitle", :min="dateMin", :max="dateMax", :active-picker.sync="activePicker", ref="picker")
			template(v-if="isLazy")
				v-spacer
				v-btn(text, color="secondary", @click="closePicker") {{$t('default.cancel')}}
				v-btn(text, :color="color", @click="lazyInput") {{$t('default.confirm')}}
</template>

<script>
	import { lazyModelMixinFactory } from "@/mixins/lazy-model-mixin"
	import FieldLocalizedMixin from "@/mixins/field-localized-mixin"
	import { localeToBcp47 } from "@/libs/utils"
	import { debounce } from "lodash"

	export default {
		name: "ui-date-field",
		mixins: [lazyModelMixinFactory([String, Array], "date"), FieldLocalizedMixin],
		props: {
			multiple: Boolean,
			range: Boolean,
			birthday: Boolean,
			showTitle: Boolean,
			lazy: Boolean,
			clearable: Boolean,
			icon: { type: [String, Boolean], default: "far fa-calendar-alt" },
			min: String,
			max: String,
			readonly: Boolean,
			color: { type: String, default: "primary" }
		},
		data() {
			return {
				showPicker: false,
				activePicker: "DATE"
			}
		},
		computed: {
			isClearable() { return this.clearable },
			isMultiple() { return this.multiple || this.range },
			isLazy() { return this.lazy || this.isMultiple },
			dateFormatted: {
				get() {
					if(this.hasValue) {
						if(this.multiple) return this._.chain(this.model).map(this.formatDate).join(", ").value()
						else if(this.range) return this.$tc("fields.ui-date-field.range", this.model.length, { from: this.formatDate(this._.head(this.model)), to: this.formatDate(this._.last(this.model)) })
						else return this.formatDate(this.model)
					}
					return ""
				},
				set: debounce(function(value) {
					if(!!value) this.model = this.parseDate(value)
					else this.model = null
				}, 300)
			},
			textAttrs() { return Object.assign({ readonly: this.multiple || this.range, label: this.labelI18n, placeholder: this.placeholderI18n }, this.$attrs, this.clearButton) },
			dateMin() { return this.min },
			dateMax() {
				if(this.birthday && !this.max) return this.$moment().format()
				else return this.max
			},
			calendarIcon() { if(this.icon !== false) return this.icon },
			locale() {
				const locale = this.$store.getters.locale
				return localeToBcp47(locale)
			}
		},
		methods: {
			closePicker() { this.showPicker = false },
			formatDate(date) { return this.$moment(date).format("DD-MM-YYYY") },
			parseDate(value) {
				const date = this.$moment(value, "DD-MM-YYYY")
				if(date.isValid()) return date.format("YYYY-MM-DD")
				else return ""
			},
			onInput() { this.closePicker() }
		},
		watch: {
			showPicker(open) { this.birthday && open && this.$nextTick(() => this.activePicker = this.hasValue ? "DATE" : "YEAR") }
		}
	}
</script>