import Vue from "vue"

const AGENCY = process.env.VUE_APP_AGENCY

const DEFAULT_CONFIG = {
	vuetify: {
		theme: {
			options: {
				customProperties: true,
			},
			themes: {
				light: {
					primary: "#1976D2",
					secondary: "#424242",
					third: "#424242",
					accent: "#82B1FF",
					error: "#FF5252",
					info: "#2196F3",
					success: "#4CAF50",
					warning: "#FFC107"
				},
			},
		}
	},
	translationKeys: {
		leftMenu: {
			policiesTitle: "policies"
		},
		paymentMethod: {
			payOnSpot: "DEALER"
		},
		eaccount: {
			user: "dealer"
		}
	},
	logo: "logo-base.svg",
	pages: {
		home: "home-passway",
		login: "passway"
	},
	layout: {
		topBar: {
			color: "grey darken-4"
		}
	},
	menuDark: false,
	colors: {
		miniProdotto: {
			true: "info",
			false: "primary"
		}
	},
	flussoInfocar: {
		enabled: false,
		car: { 
			minInsuredValueDelta: 0,
			maxInsuredValueDelta: 0
		},
		bike: { 
			minInsuredValueDelta: 0,
			maxInsuredValueDelta: 0
		}
	},
	quotation: {
		insuredObject: {
			licensePlate: {
				requiredPhase: "ACTIVATION"
			},
			chassisNumber: {
				requiredPhase: "ACTIVATION"
			},
			purchaseDate: {
				enabled: false
			},
			mileage: {
				enabled: false
			},
			registrationDate: {
				requiredPhase: "ACTIVATION",
				min: {
					byVehicleStatus: {
						NUOVO: "2Y",
						USATO: "15Y",
						USATO_QUATTRORUOTE: "15Y"
					}
				}
			},
			vehicleStatus: {
				allowed: {
					NUOVO: true,
					NUOVO_NON_TARGATO: false,
					NUOVO_TARGATO: false,
					USATO: true,
					USATO_QUATTRORUOTE: true
				},
				hint: false,
				newSubStatus: true
			},
			infocarQuotation: {
				enabled: true,
				mode: "DIALOG",
				insuredValueRange: {
					enabled: false,
					AUTOVEICOLI: { min: 0, max: 0, percentage: true },
					MOTOVEICOLI: { min: 0, max: 0, percentage: true }
				}
			},
			taxRecovery: {
				enabled: true
			},
			kilowatts: {
				enabledIf: false
			}
		},
		effectiveDate: {
			requiredPhase: "ACTIVATION",
			max: {
				fixed: "6M",
				byVehicleStatus: null
			}
		},
		videoExpertise: {
			enabled: false,
			requiredPhase: "ACTIVATION"
		}
	},
	product: {
		allowSalable: false
	},
	ivass: {
		expirationMaxReminders: 7
	}
}
export function loadTenantConfig() {
	const configs = require.context("../tenant-config", true, /[A-Za-z0-9-_,\s]+\.json$/i)
	for(const key of configs.keys()) {
		const match = key.match(/([A-Za-z0-9-_]+)\./i)
		if(match && match.length > 1) {
			const configName = match[1]
			if(configName === AGENCY) return configs(key)
		}
	}
	console.debug("loading default tenant config")
	return DEFAULT_CONFIG
}

export function getMaxEffectiveDateByVehicleState(byVehicleStatus, statoVeicolo) {
	 byVehicleStatus[statoVeicolo]
}

const TenantConfig = {
	install(Vue) {
		const tenantConfig = loadTenantConfig()
		Vue.$tenantConfig = tenantConfig
		Vue.$agency = AGENCY
		Object.defineProperty(Vue.prototype, "$tenantConfig", { get() { return tenantConfig } })
		Object.defineProperty(Vue.prototype, "$agency", { get() { return AGENCY } })
	}
}

Vue.use(TenantConfig)