<template lang="pug">
ui-page-loader(v-if="loading", :message="loadingMessage")
validation-observer(v-else, slim, ref="page-form", #default="vo")
	v-form(@submit.prevent="vo.handleSubmit(doSave)", autocomplete="off")
		v-container(fluid, pa-0)
			v-row(:no-gutters="noGutters")
				slot(name="prepend")
				v-col(cols="12")
					v-card(:flat="flat", :class="cardClass")
						slot(v-bind="{ saving, ...vo }")
			v-expand-transition
				v-row(v-show="!hideActions", no-gutters, class="mt-3")
					slot(name="actions", v-bind="{ saving, ...vo }")
						v-btn(color="secondary", dark, :to="back", v-if="hasBack", :disabled="actionsDisabled") {{backText}}
						v-spacer
						v-btn(type="submit", color="primary", :loading="saving", v-if="hasSave", :disabled="saveDisabled || actionsDisabled") {{saveText}}
</template>

<script>
export default {
	name: "ui-page-form",
	props: {
		loading: Boolean,
		loadingMessage: String,
		hideActions: Boolean,
		save: Function,
		saveLabel: String,
		saveDisabled: Boolean,
		hideSave: Boolean,
		back: [String, Object],
		backLabel: String,
		hideBack: Boolean,
		actionsDisabled: Boolean,
		flat: Boolean,
		cardClass: [String, Object, Array],
		noGutters: Boolean
	},
	data() {
		return {
			saving: false
		}
	},
	computed: {
		hasBack() { return !this._.isNil(this.back) && !this.hideBack },
		backText() { return this.backLabel || this.$t("default.cancel") },
		hasSave() { return !this._.isNil(this.save) && !this.hideSave },
		saveText() { return this.saveLabel || this.$t("default.save") }
	},
	methods: {
		async doSave() {
			if(this.hasSave) try {
				this.saving = true
				await this.save()
			} catch(response) { this.$refs["page-form"].setErrors(response.data) }
			finally { this.saving = false }
		},
		reset() {
			this.$refs["page-form"].reset()
		}
	}
}
</script>