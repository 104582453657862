import { modelMixinFactory } from "./model-mixin"

export function lazyModelMixinFactory(prop = { required: false }, currentModel = "currentModel", lazyModel = "lazyModel", model = "model", debounce = 0, inputProp = "value", inputEvent = "input") {
	const ModelMixin = modelMixinFactory(prop, model, debounce, inputProp, inputEvent)

	return {
		mixins: [ModelMixin],
		data() {
			return {
				[lazyModel]: null
			}
		},
		computed: {
			isLazy() { return true },
			[currentModel]: {
				get() { return this.isLazy ? this[lazyModel] : this[model] },
				set(value) {
					if(this.isLazy) this[lazyModel] = value
					else this[model] = value
				}
			}
		},
		methods: {
			initLazyModel() {
				if(this.isLazy) this[lazyModel] = this.cloneValue(this[model])
			},
			lazyInput() { if(this.isLazy) this[model] = this[lazyModel] }
		},
		watch: {
			[model]: {
				handler: "initLazyModel",
				immediate: true
			}
		}
	}
}

export default lazyModelMixinFactory()