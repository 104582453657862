<template lang="pug">
span Equivalente a: <b>{{label}}</b>
</template>

<script>
export default {
	name: "pdf-field-label",
	props: {
		property: { type: String, required: true }
	},
	computed: {
		label() {
			return this.$t(`components.pdf-field-resolver.${this.property}`)
		}
	}
}
</script>