<template lang="pug">
	div(class="spinkit d-flex justify-center align-center")
		div(:class="spinkitClass", :style="spinkitStyle")
			div(v-for="itemClass in items", :class="itemClass")
</template>

<script>
import { includes } from "lodash"
import { convertToUnit } from "vuetify/lib/util/helpers"

export const spinkitTypes = ["plane", "chase", "bounce", "wave", "pulse", "flow", "swing", "circle", "circle-fade", "grid", "fold", "wander"]

export function spinkitTypeValidator(type) {
	return includes(spinkitTypes, type)
}

export default {
	name: "spinkit",
	props: {
		type: { type: String, default: "plane", validator: spinkitTypeValidator },
		width: { type: [Number, String], default: 50 },
		height: { type: [Number, String], default: 50 }
	},
	computed: {
		spinkitClass() { return `sk-${this.type}` },
		spinkitStyle() {
			return {
				width: convertToUnit(this.width),
				height: convertToUnit(this.height)
			}
		},
		items() {
			switch(this.type) {
				case "chase": return this.createItems(6, "sk-chase-dot")
				case "bounce": return this.createItems(2, "sk-bounce-dot")
				case "wave": return this.createItems(5, "sk-wave-rect")
				case "flow": return this.createItems(3, "sk-flow-dot")
				case "swing": return this.createItems(2, "sk-swing-dot")
				case "circle": return this.createItems(12, "sk-circle-dot")
				case "circle-fade": return this.createItems(12, "sk-circle-fade-dot")
				case "grid": return this.createItems(9, "sk-grid-cube")
				case "fold": return this.createItems(4, "sk-fold-cube")
				case "wander": return this.createItems(3, "sk-wander-cube")
				default: return []
			}
		}
	},
	methods: {
		createItems(length, itemClass) {
			return this._.times(length, this._.constant(itemClass))
		}
	}
}
</script>

<style>
	@import "~spinkit/spinkit.css";
	:root {
		--sk-color: var(--v-primary-base);
	}
	.sk-flow {
		align-items: center;
	}
</style>