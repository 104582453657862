import Vue from "vue"
import Nais from "./nais.vue"
import router from "./router"
import store from "./store"

// plugins
import "./plugins/multitenant"
import vuetify from "./plugins/vuetify"
import i18n from "./plugins/i18n"
import "./plugins/lodash"
import "./plugins/utils"
import "./plugins/axios"
import "./plugins/moment"
import "./plugins/numeral"
import "./plugins/sweetalert"
import "./plugins/vee-validate"
import "./plugins/global-components"
import "./plugins/global-mixins"
import "./plugins/draggable"
import "./plugins/favico"
import "./plugins/anime"
import "./plugins/nais"
import "./plugins/cookies"
import "./plugins/global-events"
import "./plugins/nais-websocket"

// styles
import "./assets/style/nais.scss"

Vue.config.productionTip = false
Vue.config.devtools = process.env.VUE_APP_DEVTOOLS === "yes" || process.env.NODE_ENV === "development"

Vue.config.errorHandler = function(error, vm, info) {
	console.error("on vue error", error.name, error.message, info, error.stack)
}
addEventListener("error", function(event) {
	console.error("on event error", event)
})
addEventListener("unhandledrejection", function(event) {
	console.error("on promise error", event)
})
window.onerror = function(a, b, c, d, e) {
	console.error("on window error", a, b, c, d ,e)
}

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: h => h(Nais),
	computed: {
		locale: {
			get() { return this.$store.getters.locale },
			set(locale) { this.$store.commit("setLocale", locale) }
		}
	},
	mounted() {
		if(this._.isNil(this.locale) || this.locale === "") this.locale = process.env.VUE_APP_DEFAULT_LOCALE
	}
}).$mount("#nais")
