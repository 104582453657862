import Vue from "vue"
import Favico from "favico.js"

const VueFavico = {
	install(Vue, config = { animation: "none" }) {
		const favico = new Favico(config)
		Vue.favico = favico
		Object.defineProperty(Vue.prototype, "$favico", {
			get() { return favico },
			set(value) { favico.badge(value) }
		})
	}
}

Vue.use(VueFavico)
