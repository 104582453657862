<script>
import I18nFormatterMixin from "@/mixins/formatter/i18n-formatter-mixin"
import FunctionFormatterMixin from "@/mixins/formatter/function-formatter-mixin"
import { getTranslation } from "@/libs/i18n"
import { isBoolean, isString, isNil } from "lodash"

function breakSpaceValidator(value) {
	if(isNil(value) || isBoolean(value)) return true
	if(isString(value)) return ["header", "cell", "both"].indexOf(value.toLowerCase()) !== -1
	return false
}

export default {
	name: "ui-table-column",
	mixins: [I18nFormatterMixin, FunctionFormatterMixin],
	props: {
		header: String,
		prop: String,
		sortable: Boolean,
		sortProp: String,
		width: [Number, String],
		align: { type: String, validator: value => ["left", "center", "right"].indexOf(value.toLowerCase()) !== -1 },
		rowspan: [Number, Function],
		colspan: [Number, Function],
		breakSpace: { type: [Boolean, String], validator: breakSpaceValidator, default: false }
	},
	data() {
		return {
			columnIndex: -1
		}
	},
	computed: {
		columnId() {
			return this._.uniqueId("ui-table-column-")
		},
		owner() {
			let parent = this.$parent
			while (parent && !parent.tableId) parent = parent.$parent
			return parent
		},
		classes() {
			const classes = {}
			this._.each(this.$attrs, (v, k) => classes[k] = this._.isBoolean(v) ? v : true)
			return classes
		},
		cellClasses() {
			const classes = {
				"text-center": /center/i.test(this.align),
				"text-right": /right/i.test(this.align),
				"text-left": /left/i.test(this.align),
				"white-space--break-spaces": this.cellBreakSpace
			}
			return Object.assign(this.classes, classes)
		},
		sortOn() {
			return this.sortProp || this.prop
		},
		columnWidth() {
			if (this._.isNumber(this.width)) return `${this.width}px`
			else if (this._.isString(this.width)) return this.width
		},
		cellSlotName() {
			if(!this._.isNil(this.prop)) return `cell.${this.prop}`
			else return `cell.${this.columnIndex}`
		},
		headerSlotName() {
			if(!this._.isNil(this.prop)) return `header.${this.prop}`
			else return `header.${this.columnIndex}`
		},
		cellBreakSpace() {
			return [true, "cell", "both"].indexOf(this.breakSpace) !== -1
		},
		headerBreakSpace() {
			return [true, "header", "both"].indexOf(this.breakSpace) !== -1
		}
	},
	methods: {
		renderHeaderText() {
			return getTranslation(this.header)
		},
		formatValue(value) {
			if(!this._.isNil(value)) {
				if (!this._.isNil(this.i18nPrefix)) return this.i18nTranslate(value)
				else if (!this._.isNil(this.format)) return this.translate(value)
				else return value
			}
		},
		renderCellValue(item, rowKey) {
			const value = this._.get(item, this.prop)
			const formattedValue = this.formatValue(value)
			const props = {
				key: rowKey,
				item,
				prop: this.prop,
				value,
				formattedValue
			}
			if(this.owner.hasExpansion) {
				props.toggleExpansion = () => this.owner.toggleExpansion(rowKey)
				props.expanded = this.owner.isExpanded(rowKey)
			}
			if(this._.isFunction(this.owner.updateItem)) props.updateItem = itemUpdated => this.owner.updateItem(itemUpdated, rowKey)
			if(this._.isFunction(this.owner.reload)) props.reload = () => this.owner.reload()
			if (this.$scopedSlots.default) return this.$scopedSlots.default(props)
			return formattedValue
		},
		hasCustomHeader() {
			return !!this.$scopedSlots.header
		},
		renderCustomHeader(props) {
			return this.$scopedSlots.header(props)
		},
		hasHeaderExtension() {
			return !!this.$slots.headerExtension
		},
		renderHeaderExtension() {
			return this.$slots.headerExtension
		},
		getRowspan(item) {
			if(this._.isFunction(this.rowspan)) return this.rowspan(item)
			else return this.rowspan
		},
		getColspan(item) {
			if(this._.isFunction(this.colspan)) return this.colspan(item)
			else return this.colspan
		}
	},
	render(h) {
		return h("div", {}, this.$slots.default)
	},
	mounted() {
		this.owner.addColumn(this)
	},
	beforeDestroy() {
		this.owner.removeColumn(this)
	}
}
</script>