import router from "@/router"
import { get, isNil } from "lodash"

export function checkMaintenance(error) {
	console.debug("maintenance check")
	const response = get(error, "response")
	if(!isNil(response)) {
		const status = get(response, "status", 400)
		if(status === 503) {
			console.debug("maintenance mode detected by axios handler")
			router.push({ name: "maintenance" })
			return Promise.reject("maintenance mode")
		}
		return Promise.reject(response)
	}
	return Promise.reject(error)
}