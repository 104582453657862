import { saveAs } from "file-saver"
import { base64ToFile, parseApi } from "@/libs/utils"
import * as _ from "lodash"

export function getContentType(response) { return _.get(response, "headers.content-type") }

export function getFileName(response) { 
	const contentDisposition = _.chain(response).get("headers.content-disposition").split("=").last().value()
	return contentDisposition ? contentDisposition.replace(/"/g, '') : ""
}

export async function downloadFile(axios, api, fileName = null, contentType = null, responseType = "arraybuffer") {
	const request = parseApi(api)
	if(_.isNil(request.responseType)) request.responseType = responseType
	console.debug("file-saver-mixin download file", request)
	const response = await axios.request(request)
	if(_.isNil(fileName)) fileName = getFileName(response)
	if(_.isNil(contentType)) contentType = getContentType(response)
	console.debug("file-saver-mixin downloaded file", fileName, contentType, response)
	return { data: response.data, contentType, fileName, response, request }
}

export default {
	methods: {
		async downloadFile(api, fName = null, cType = null) {
			const { data, contentType, fileName } = await downloadFile(this.axios, api, fName, cType)
			const base64 = Buffer.from(data, "binary").toString("base64")
			return base64ToFile(base64, fileName, contentType)
		},
		async saveFile(api, fName = null) {
			const { data, fileName } = await downloadFile(this.axios, api, fName, null, "blob")
			const blob = new Blob([data])
			saveAs(blob, fileName)
		},
		saveLocalJson(fileName, json, type = "application/json") {
			const data = JSON.stringify(json)
			this.saveLocalText(fileName, data, type)
		},
		saveLocalText(fileName, content, type = "text/plain") {
			const blob = new Blob([content], { type })
			saveAs(blob, fileName)
		}
	}
}