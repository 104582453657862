import ArrayFormatterMixin from "./array-formatter-mixin"

export default {
	mixins: [ArrayFormatterMixin],
	props: {
		i18nPrefix: [String, Array]
	},
	methods: {
		i18nFormat(value) {
			if(!this._.isNil(value)) {
				if(this._.isArray(this.i18nPrefix)) return this.$tc(`${this._.head(this.i18nPrefix)}.${value}`, ...this._.tail(this.i18nPrefix))
				else return this.$t(`${this.i18nPrefix}.${value}`)
			}
			else return ""
		},
		i18nTranslate(value) {
			if(this._.isArray(value)) return this.formatArray(value, this.i18nFormat)
			else return this.i18nFormat(value)
		}
	}
}