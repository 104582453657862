


const user = {
	state: {
		avatar: "",
		info: {
			id: 0,
			nominativo: "",
			gruppo: "",
			username: "",
			agenzia: "",
			authorities: [],
			children: [],
			roleDetails: {},
			agencyRoles: []
		}
	},
	getters: {
		avatar: state => state.avatar,
		userInfo: state => state.info
	},
	mutations: {
		updateAvatar: (state, avatar) => state.avatar = avatar,
		setUserInfo: (state, userInfo) => state.info = userInfo,
		clear: state => {
			state.avatar = ""
			state.info = {}
		}
	}
}

export default user