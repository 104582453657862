import i18n from "@/plugins/i18n"

const confirm = {
	callback: confirmed => console.debug("Confirmed ", confirmed),
	open: false,
	message: "",
	icon: "warning",
	actions: { ok: "default.confirm", ko: "default.cancel" }
}

const layout = {
	sideMenuOpened: false,
	notificationOpened: false,
	userMenuOpened: false
}

const app = {
	state: {
		exception: "",
		success: "",
		topBarTitle: "",
		confirm: { ...confirm },
		locale: "",
		version: {
			be: "",
			fe: ""
		},
		config: {
			sezioneSinistri: false,
			forzaAnnullamentoGaranzie: true,
			maintenance: false
		},
		layout: { ...layout },
		agencyInfo: {
			website: "",
			name: ""
		}
	},
	getters: {
		confirmOpen: state => state.confirm.open,
		confirmMessage: state => state.confirm.message,
		confirmIcon: state => state.confirm.icon,
		confirmActions: state => state.confirm.actions,
		locale: state => state.locale,
		beVersion: state => state.version.be,
		feVersion: state => state.version.fe,
		sezioneSinistriAbilitata: state => state.config.sezioneSinistri,
		annullamentoGaranzieForzato: state => state.config.forzaAnnullamentoGaranzie,
		maintenance: state => state.config.maintenance,
		sideMenuOpened: state => state.layout.sideMenuOpened,
		notificationOpened: state => state.layout.notificationOpened,
		userMenuOpened: state => state.layout.userMenuOpened,
		agencyInfo: state => state.agencyInfo
	},
	mutations: {
		setException: (state, message) => state.exception = message,
		setSuccess: (state, message) => state.success = message,
		setTopBarTitle: (state, title) => state.topBarTitle = title,
		showConfirm: (state, { callback, message, icon, actions }) => {
			state.confirm.callback = callback
			state.confirm.message = message
			state.confirm.icon = icon || "warning"
			state.confirm.actions = actions || { ok: "default.confirm", ko: "default.cancel" }
			state.confirm.open = true
		},
		closeConfirm: (state, confirmed) => {
			state.confirm.callback(confirmed)
			state.confirm.open = false
		},
		setLocale: (state, locale) => {
			state.locale = locale
			i18n.locale = locale
		},
		setVersion: (state, version) => state.version = version,
		setBeVersion: (state, be) => state.version = Object.assign({}, state.version, { be }),
		setConfig: (state, { key, value }) => {
			if(key === "SEZIONE_SINISTRI") state.config.sezioneSinistri = value
			if(key === "FORZA_ANNULLAMENTO_GARANZIE") state.config.forzaAnnullamentoGaranzie = value
			if(key === "MAINTENANCE") state.config.maintenance = value
		},
		setAgencyInfo: (state, agencyInfo) => state.agencyInfo = agencyInfo,
		toggleSideMenu: (state, opened) => state.layout.sideMenuOpened = opened,
		toggleNotification: (state, opened) => state.layout.notificationOpened = opened,
		toggleUserMenu: (state, opened) => state.layout.userMenuOpened = opened,
		clear: state => {
			state.exception = ""
			state.topBarTitle = ""
			state.confirm = { ...confirm }
			state.layout = { ...layout }
		}
	}
}

export default app