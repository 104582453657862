import Vue from "vue"
import VueI18n from "vue-i18n"
import it from "vee-validate/dist/locale/it.json"

Vue.use(VueI18n)

function loadLocaleMessages() {
	const locales = require.context("../i18n", true, /[A-Za-z0-9-_,\s]+\.json$/i)
	const messages = {}
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			const localized = locales(key)
			const validation = localized.validation
			const veeValidate = {
				validation: Object.assign({}, validation, { messages: Object.assign(it.messages, validation.messages) })
			}
			messages[locale] = { ...localized, ...veeValidate }
		}
	})
	return messages
}

const i18n = new VueI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || "it",
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "it",
	messages: loadLocaleMessages(),
	silentFallbackWarn: true,
	silentTranslationWarn: true,
	/*missing(locale, key, vm, values) {
		const names = /validation.names\.(.+)/.exec(key)
		if(names) return names[1]
		const field = /validation.fields\.(.+)\.(.+)/.exec(key)
		if(field) return i18n.t(`validation.messages.${field[2]}`, ...values)
		return key
	}*/
})

export default i18n
