import FileSaverMixin from "./file-saver-mixin"
import { fileToBase64, isS3File } from "@/libs/utils"

export default {
	mixins: [FileSaverMixin],
	methods: {
		isS3File: isS3File,
		getFileName(file) {
			if(this.isS3File(file)) return file.fileName
			else if(!this._.isNil(file)) return file.name
			else return ""
		},
		async downloadS3File({ id, fileName, contentType }, url = false) {
			const file = await this.downloadFile(`/s3/download/${id}`, fileName, contentType)
			console.debug("downloaded s3 file", id, file)
			return fileToBase64(file, url)
		},
		async saveS3File(id) { await this.saveFile(`/s3/download/${id}`) }
	}
}