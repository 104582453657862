import Vue from "vue"
import { ValidationProvider, ValidationObserver, configure, extend } from "vee-validate/dist/vee-validate.full.esm"
import i18n from "./i18n"
import { isArray, uniqWith, isEqual, isFunction, isNumber, some, findIndex, get, isNil, pickBy, forEach, size, differenceWith, reduce, find, isEmpty, includes, isString } from "lodash"
import { compareDurate } from "@/libs/utils"

configure({
	defaultMessage: (field, values) => {
		console.debug("get validation message for field", field, "and rule", values._rule_)
		if(i18n.te(`validation.names.${field}`)) values._field_ = i18n.t(`validation.names.${field}`)
		else values._field_ = field
		if(i18n.te(`validation.fields.${field}.${values._rule_}`)) return i18n.t(`validation.fields.${field}.${values._rule_}`, values)
		else return i18n.t(`validation.messages.${values._rule_}`, values)
	}
})

extend("checked", {
	validate(value) {
		return {
			required: true,
			valid: value === true
		}
	}
})

extend("minLength", {
	validate(value, { length }) {
		console.debug("validate min length", value, length, value.length >= +length)
		return value.length >= +length
	},
	params: ["length"],
	computesRequired: true
})

extend("unique", {
	validate(values, args) {
		const comparator = isFunction(args.comparator) ? args.comparator : isEqual
		if(isArray(values)) return values.length === uniqWith(values, comparator).length
		return true
	},
	params: ["comparator"]
})

extend("decimal", {
	validate(value, { decimals = "2", separator = "." }) {
		if(Number(decimals) === 0) return /^-?\d*$/.test(value)
		const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`
		const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)
		return regex.test(value)
	},
	params: ["decimals", "separator"]
})

extend("codiceFiscale", {
	validate(value, { tipo }) {
		if(!value) return true
		let regex = new RegExp(/^[a-z]{6}(?:\d|[lmnpqrstuvL]){2}[a-z](?:\d|[lmnpqrstuvL]){2}[a-z](?:\d|[lmnpqrstuvL]){3}[a-z]$/, "i")
		if(tipo === "SOCIETA") regex = new RegExp(/^[a-zA-Z0-9]{11}$/, "i")
		return regex.test(value)
	},
	params: ["tipo"]
})

extend("garanzieProdotto", {
	validate(garanzie) {
		const check = some(garanzie, item => get(item, "garanzia.obbligatoria", false))
		console.debug("validate garanzie prodotto", garanzie, check)
		return check
	}
})

extend("garanzieUtente", {
	validate(garanzie) {
		const check = some(garanzie, item => get(item, "garanzia.obbligatoria", false) || get(item, "garanzia.preSelezionata", false))
		console.debug("validate garanzie utente", garanzie, check)
		return check
	}
})

extend("mutueEsclusioniObbligatorie", {
	validate(garanzie, { prop = "garanzia.id", required = true }) {
		console.debug("validate mutue esclusioni obbligatorie", prop, required)
		if(size(garanzie) <= 1) return true
		return reduce(garanzie, (check, { garanzia, riferimentoGaranzia }) => {
			if(!check) return check
			if(garanzia.preSelezionata || !required || !garanzia.obbligatoria) return true
			const mutueEsclusioni = get(riferimentoGaranzia, "mutueEsclusioni", [])
			if(!isEmpty(mutueEsclusioni)) {
				const mutuaEsclusionePreSelezionata = find(garanzie, ({ garanzia: garanziaConfrontata }) => get(garanziaConfrontata, prop) !== get(garanzia, prop) && garanziaConfrontata.preSelezionata && includes(mutueEsclusioni, garanziaConfrontata.tipo))
				return !isNil(mutuaEsclusionePreSelezionata)
			}
			return true
		}, true)
	},
	params: ["prop", "required"]
})

extend("inList", {
	validate(value, { list = [], comparator = isEqual}) {
		console.debug("inList validation", list, value)
		if(isArray(value)) return size(differenceWith(value, list, comparator)) === 0
		return findIndex(list, item => comparator(item, value)) !== -1
	},
	params: ["list", "comparator"]
})

extend("minmax", {
	validate(value, args) {
		const bMin = get(args, "minimo", 0.0), bMax = get(args, "massimo", 0.0)
		if(isNumber(value)) return value >= bMin && value <= bMax
		const vMin = get(value, "minimo", 0.0), vMax = get(value, "massimo", 0.0)
		return !(vMin >= vMax || vMin <= bMin || vMax >= bMax)
	},
	params: ["minimo", "massimo"]
})

extend("dateBetween", {
	validate(value, { min, max, inclusion = false }) {
		return Vue.moment(value).isBetween(min, max, undefined, inclusion ? "[]" : "()")
	},
	params: ["min", "max", "inclusion"]
})

extend("codiciProdottoUnici", {
	validate(codici) {
		const codiciUnivoci = uniqWith(codici, (c1, c2) => {
			const c1Filter = pickBy(c1, (_, key) => key !== "codice")
			const c2Filter = pickBy(c2, (_, key) => key !== "codice")
			return isEqual(c1Filter, c2Filter)
		})
		console.debug("validate codici prodotto univoci", codici, codiciUnivoci)
		return size(codici) === size(codiciUnivoci)
	}
})

extend("codiciProdottoCorretti", {
	validate(codici) {
		const totByConsumer = {}
		const countWithGaranzie = {}
		forEach(codici, ({ consumer, garanzie }) => {
			const countConsumer = totByConsumer[consumer] || 0
			totByConsumer[consumer] = countConsumer + 1
			if(isArray(garanzie) && garanzie.length > 0) {
				const countGaranzie = countWithGaranzie[consumer] || 0
				countWithGaranzie[consumer] = countGaranzie + 1
			}
		})
		console.debug("validate codici prodotto correttamente configurati", codici, totByConsumer, countWithGaranzie)
		let isValid = true
		forEach(countWithGaranzie, (countGaranzie, consumer) => {
			const tot = totByConsumer[consumer] || 0
			if(countGaranzie > 0 && countGaranzie < tot) isValid = false
		})
		return isValid
	}
})

extend("scontiPredefiniti", {
	validate(sconti) {
		const scontiUnivoci = uniqWith(sconti, (s1, s2) => {
			const s1Filter = pickBy(s1, (_, key) => key !== "sconto")
			const s2Filter = pickBy(s2, (_, key) => key !== "sconto")
			return isEqual(s1Filter, s2Filter)
		})
		console.debug("validate sconti predefiniti", sconti, scontiUnivoci)
		return size(sconti) === size(scontiUnivoci)
	}
})

extend("durataValida", {
	validate(durata, { ne, gt, ge, lt, le, between }) {
		if(isNil(durata)) return true
		console.debug("is durata valida", durata, ne, gt, ge, lt, le, between)
		let check = true
		if(ne) check &= compareDurate(durata, ne) !== 0
		if(gt) check &= compareDurate(durata, gt) > 0
		if(ge) check &= compareDurate(durata, ge) >= 0
		if(lt) check &= compareDurate(durata, lt) < 0
		if(le) check &= compareDurate(durata, le) <= 0
		if(between) {
			const min = get(between, "min"), max = get(between, "max")
			if(!isNil(min) && !isNil(max)) {
				check &= compareDurate(durata, min) >= 0
				check &= compareDurate(durata, max) <= 0
			}
		}
		return check
	},
	params: ["ne", "gt", "ge", "lt", "le", "between"]
})

extend("positive", {
	validate(value, { includeZero = false }) {
		if(isNumber(value)) {
			if(includeZero) return value >= 0
			else return value > 0
		}
		return true
	},
	params: ["includeZero"]
})

extend("nameOrSurname", {
	validate(value) {
		if(isString(value)) {
			const regex = new RegExp(/^[a-zÀ-ÿ'\s\-]+$/, "i")
			return regex.test(value)
		}
		return false
	}
})

extend("marginiPercipienteUnique", {
	validate(margini) {
		const marginiNoDuplicates = uniqWith(margini, (m1, m2) => {
			const m1Durata = get(m1, "durata", null)
			const m2Durata = get(m2, "durata", null)
			const m1Zona = get(m1, "zona", null)
			const m2Zona = get(m2, "zona", null)
			const m1Operazione = get(m1, "operazione", "EMISSIONE")
			const m2Operazione = get(m2, "operazione", "EMISSIONE")
			return isEqual(m1Durata, m2Durata) && isEqual(m1Zona, m2Zona) && isEqual(m1Operazione, m2Operazione)
		})
		console.debug("margini unique per percipiente", margini, marginiNoDuplicates)
		return size(margini) === size(marginiNoDuplicates)
	}
})

Vue.component("validation-provider", ValidationProvider)
Vue.component("validation-observer", ValidationObserver)