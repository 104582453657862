import Vue from "vue"
import lodash from "lodash"

const VueLodash = {
	install(Vue) {
		Vue._ = lodash
		Object.defineProperty(Vue.prototype, "_", { get() { return lodash } })
		Vue.filter("uppercase", lodash.toUpper)
		Vue.filter("lowercase", lodash.toLower)
		Vue.filter("camelcase", lodash.camelCase)
		Vue.filter("capitalize", lodash.capitalize)
		Vue.filter("upperfirst", lodash.upperFirst)
		Vue.filter("kebabcase", lodash.kebabCase)
		Vue.filter("snakecase", lodash.snakeCase)
		Vue.filter("trim", lodash.trim)
		Vue.filter("map", lodash.map)
		Vue.filter("join", lodash.join)
	}
}

Vue.use(VueLodash)