<script>
	import ApiMixin from "@/mixins/api-mixin"
	import LoadingMixin from "@/mixins/loading-mixin"
	import RenderHelperMixin from "@/mixins/render-helper-mixin"

	export default {
		name: "ui-remote-select",
		mixins: [RenderHelperMixin, ApiMixin, LoadingMixin],
		props: {
			value: { required: false },
			disabled: Boolean,
			errorMessage: { type: String, default: "Errore imprevisto" },
			errorHandler: Function,
			alwaysFetch: Boolean
		},
		data() {
			return {
				items: []
			}
		},
		computed: {
			fetchTrigger() {
				console.debug("ui-remote-select fetch triggered")
				return { apiConfig: this.apiConfig, disabled: this.disabled }
			}
		},
		methods: {
			async fetchItems() {
				if(!this.disabled || this.alwaysFetch) try {
					this.lazyLoading = !this.disabled
					this.items = []
					const response = await this.axios.request(this.apiConfig)
					this.items = response.data
				} catch(error) {
					console.error("ui-remote-select error", error)
					if(this.$isTruthy(this.errorHandler)) this.errorHandler(error)
					else this.$store.commit("setException", this.errorMessage)
				} finally {
					this.lazyLoading = false
					this.$emit("update:items", this.items)
				}
			}
		},
		watch: {
			fetchTrigger: {
				handler: "fetchItems",
				immediate: true
			}
		},
		render(h) {
			const attrs = {
				value: this.value,
				loading: this.lazyLoading,
				items: this.items,
				disabled: this.disabled
			}
			const data = {
				on: Object.assign({}, this.$listeners),
				attrs: Object.assign({}, this.$attrs, attrs)
			}
			return this.renderComponentWithSlots(h, "ui-select", data)
		}
	}
</script>