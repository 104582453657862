<template>
	<div class="ui-file-input" :class="{ 'd-inline-flex': btn, 'w-100': !btn }">
		<slot name="activator" v-bind="activatorSlot">
			<v-input v-if="preview || btn" :prepend-icon="prependIcon" v-bind="activatorAttrs">
				<v-row align="center" class="ma-0">
					<v-btn @click="activateFileInput" small color="primary" :disabled="readonly">{{fieldLabel}}</v-btn>
					<span class="ml-sm-2 mt-2 mt-sm-0" v-if="!hideSelected">{{textTruncated}}</span>
				</v-row>
			</v-input>
			<v-textarea v-else readonly :prepend-icon="prependIcon" :value="text" :label="fieldLabel" v-bind="activatorAttrs" v-on="activatorEvents" auto-grow rows="1"/>
		</slot>
		<input type="file" @change="onChange" ref="file-input" class="d-none" :multiple="multiple" :accept="accept">
		<slot name="preview" v-if="preview" v-bind="{ model }">
			<ui-file-list v-model="model" :clearable="clearable"/>
		</slot>
		<slot name="append" v-bind="{ model }"/>
	</div>
</template>

<script>
import ModelMixin from "@/mixins/model-mixin"
import S3Mixin from "@/mixins/s3-mixin"
import UiFileList from "./ui-file-list"
import { fileToBase64 } from "@/libs/utils"

export default {
	name: "ui-file-field",
	mixins: [ModelMixin, S3Mixin],
	components: { UiFileList },
	props: {
		accept: String,
		icon: { type: String, default: "far fa-file" },
		hideIcon: Boolean,
		multiple: Boolean,
		clearable: Boolean,
		preview: Boolean,
		btn: Boolean,
		label: { type: String, default: "fields.ui-file-field.select" },
		hideSelected: Boolean,
		errorMessages: [String, Array],
		readonly: Boolean,
		base64: Boolean
	},
	computed: {
		isClearable() { return this.clearable },
		isMultiple() { return this.multiple },
		prependIcon() { if(!this.hideIcon) return this.icon },
		text() {
			if(this.hasValue) {
				if(this.multiple) return this._.chain(this.model).map(file => this.getFileName(file)).join(", ").value()
				else return this.getFileName(this.model)
			}
			return ""
		},
		activatorAttrs() { return Object.assign({}, this.$attrs, this.clearButton, { errorMessages: this.errorMessages }) },
		activatorEvents() { return Object.assign({}, { click: this.activateFileInput }, this.clearEvent) },
		activatorSlot() { return { attrs: this.activatorAttrs, on: this.activatorEvents, textTruncated: this.textTruncated, text: this.text } },
		fieldLabel() {
			if(this.$te(this.label)) return this.$tc(this.label, this.isMultiple ? 2 : 1)
			return this.label
		},
		textTruncated() {
			let count = 0
			if(this.hasValue) {
				if(this.isMultiple) count = this.model.length
				else count = 1
			}
			return this.$tc("fields.ui-file-field.selected", count, { fileName: this.text })
		}
	},
	methods: {
		activateFileInput() {
			this.$refs["file-input"].value = null
			this.$refs["file-input"].click()
		},
		async onChange(event) {
			const files = event.target.files || event.dataTransfer.files
			if(!files.length) return
			const formatted = await this.formatIfRequested(files)
			console.debug("ui-file-input on change", formatted)
			if(this.multiple) this.model = formatted
			else this.model = this._.head(formatted)
		},
		getFileName(file) {
			if(this.base64) return this._.get(file, "fileName", "UNKNOWN")
			else return S3Mixin.methods.getFileName.call(this, file)
		},
		async formatIfRequested(files) {
			return Promise.all(this._.map(files, async (file) => {
				if(this.base64) return { fileName: file.name, contentType: file.type, encodedContent: await fileToBase64(file) }
				else return file
			}))
		}
	}
}
</script>