<template lang="pug">
div(class="ui-period-field")
	ui-output-field(:value="prettyPeriod", :label="labelI18n", v-if="readonly")
	v-menu(v-else, v-model="open", :close-on-content-click="false", transition="slide-y-transition", offset-y, max-width="290px", min-width="290px")
		template(#activator="{ on, attrs }")
			v-text-field(:value="prettyPeriod", v-on="on", v-bind="{ ...$attrs, ...attrs }", readonly, :label="labelI18n", :placeholder="placeholderI18n", class="cursor-pointer")
				template(#prepend)
					v-icon(@click="doOpen", :color="iconColor") {{icon}}
				template(#append, v-if="clearable")
					v-icon(@click="clear") far fa-trash-alt clear-icon pt-1
		validation-observer(slim, #default="{ handleSubmit }")
			v-card
				v-card-title {{prettyUserInput | capitalize}}
				v-divider
				v-card-text
					v-row(dense)
						v-col(cols="12")
							ui-field(type="number", step="1", v-model.number="period.years", label="components.ui-period-field.labels.years", name="components.ui-period-field.years", rules="min_value:0")
						v-col(cols="12")
							ui-field(type="number", step="1", v-model.number="period.months", label="components.ui-period-field.labels.months", name="components.ui-period-field.months", rules="min_value:0")
						v-col(cols="12")
							ui-field(type="number", step="1", v-model.number="period.days", label="components.ui-period-field.labels.days", name="components.ui-period-field.days", rules="min_value:0")
				v-divider
				v-card-actions
					v-btn(text, color="secondary", @click="reset") {{$t('default.cancel')}}
					v-spacer
					v-btn(text, :color="color", @click="handleSubmit(doInput)") {{$t('default.confirm')}}
</template>

<script>
import FieldLocalizedMixin from "@/mixins/field-localized-mixin"

const defaultPeriod = {
	years: 0,
	months: 0,
	days: 0
}

export default {
	name: "ui-period-field",
	mixins: [FieldLocalizedMixin],
	model: {
		event: "change"
	},
	props: {
		value: String,
		readonly: Boolean,
		noYears: Boolean,
		noMonths: Boolean,
		noDays: Boolean,
		color: { type: String, default: "primary" },
		icon: { type: [String, Boolean], default: "far fa-calendar-check" },
		clearable: Boolean
	},
	data() {
		return {
			period: Object.assign({}, defaultPeriod),
			open: false
		}
	},
	computed: {
		prettyPeriod() {
			const period = this.periodStringToObject(this.value)
			return this.prettify(period)
		},
		prettyUserInput() {
			const pretty = this.prettify(this.period)
			if(pretty === "") return this.$t("components.ui-period-field.no-period")
			return pretty
		},
		iconColor() {
			if(this.open) return this.color
		}
	},
	methods: {
		periodStringToObject(string) {
			if(!this.$isTruthy(string)) return Object.assign({}, defaultPeriod)
			const result = /P?(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?/i.exec(string)
			if(result.length < 4) {
				this.$store.commit("setException", `Errore decodifica periodo ${string}`)
				return Object.assign({}, defaultPeriod)
			}
			const years = +this._.get(result, 1, 0)
			const months = +this._.get(result, 2, 0)
			const days = +this._.get(result, 3, 0)
			return Object.assign({}, { years, months, days })
		},
		periodObjectToString(object) {
			if(!this.$isTruthy(object)) return ""
			const years = this._.get(object, "years", 0)
			const months = this._.get(object, "months", 0)
			const days = this._.get(object, "days", 0)
			const strings = ["P"]
			if(years > 0) strings.push(`${years}Y`)
			if(months > 0) strings.push(`${months}M`)
			if(days > 0) strings.push(`${days}D`)
			return strings.join("")
		},
		doInput() {
			this.$emit("change", this.periodObjectToString(this.period))
			this.doClose()
		},
		clear() {
			this.period = this.periodStringToObject()
			this.$emit("change", null)
			this.doClose()
		},
		reset() {
			this.period = this.periodStringToObject(this.value)
			this.doClose()
		},
		doOpen() { this.open = true },
		doClose() { this.open = false },
		prettify(period) {
			const strings = []
			if(period.years > 0) strings.push(this.$tc("components.ui-period-field.years", period.years))
			if(period.months > 0) {
				if(period.days > 0) strings.push(", ")
				else strings.push(` ${this.$t("default.and")} `)
				strings.push(this.$tc("components.ui-period-field.months", period.months))
			}
			if(period.days > 0) {
				strings.push(` ${this.$t("default.and")} `)
				strings.push(this.$tc("components.ui-period-field.days", period.days))
			}
			return strings.join("")
		}
	},
	watch: {
		value: {
			handler(string) {
				this.period = this.periodStringToObject(string)
			},
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped>
.ui-period-field::v-deep {
	.cursor-pointer {
		input {
			cursor: pointer;
		}
	}
}
</style>