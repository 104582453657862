import store from "../store"
import router from "../router"
import axios from "axios"
import { isArray, isString, forEach, split, get, isNil, isEqual, reduce, isEmpty } from "lodash"
import * as _ from "lodash"

function goToLogin() { if(router.currentRoute.name !== "login") router.push({ name: "login" }) }

function goToHome() { if(router.currentRoute.name !== "home") router.push({ name: "home" }) }

function goToRefreshPassword(username) {
	if(router.currentRoute.name !== "refresh-password") router.push({ name: "refresh-password", params: { username }, query: router.currentRoute.query })
}

export async function login(username, password) {
	try {
		await axios.post("/login", { username, password })
		store.commit("setAuthenticated", true)
		await fetchUserInfo()
	} catch(response) {
		console.error("login error", response)
		if(response.data.error === "credentials_expired") {
			console.debug("credentials was expired, go to refresh password")
			goToRefreshPassword(username)
			return Promise.reject(response)
		} else store.commit("setLoginError", true)
	}
}

export async function oauthCallback(to, from, next) {
	console.debug("oauth callback", to)
	if(!!to.query.errors) {
		console.error("OAuth callback errors", to.query.errors)
		next({ name: "login", params: { error: true } })
	} else {
		store.commit("setAuthenticated", true)
		await fetchUserInfo()
		const redirectTo = isNil(to.query.p) ? { name: "home" } : atob(to.query.p)
		next(redirectTo)
	}
}

export async function loginAs(username) {
	console.debug("try to login as", username)
	try {
		const currentUser = store.getters.userInfo
		await axios.post("/login-as", { username })
		store.commit("loginAs", currentUser)
		await fetchUserInfo()
	} catch(e) { store.commit("setLoginAsError", true) }
}

export async function loginAsCallback(to, from, next) {
	console.debug("login as callback", to)
	if(!!to.params.username) {
		const username = atob(to.params.username)
		await loginAs(username)
		next({ name: "home" })
	} else next(false)
}

export async function refreshPassword(username, password, nuovaPassword) {
	try {
		await axios.post("/utenti/change-password", {username, password, nuovaPassword})
		await login(username, nuovaPassword)
	} catch(e) { console.error("errore change password", e, e.response) }
}

async function refresh(config) {
	try {
		console.debug("Try refresh access token")
		await axios.post("/refresh")
		store.commit("setAuthenticated", true)
		return await axios(config)
	} catch(e) {
		console.error("Refresh error", e)
		goToLogin()
	}
}

export function parseRolesArray(...roles) {
	return reduce(roles, (parsed, role) => {
		if(isArray(role)) parsed.push(...parseRolesArray(...role))
		else if(isString(role)) forEach(split(role, /[, ]/), r => {
			if(!isNil(r) && !isEmpty(r)) parsed.push(r)
		})
		return parsed
	}, [])
}

export function beforeRouteEnter(to, from, next) {
	const needAuthentication = to.matched.find(route => route.meta.isAuthenticated)
	if(!_.isNil(needAuthentication)) {
		console.debug("need authentication to access route", to.fullPath)
		if(!isAuthenticated()) {
			console.warn("user is not authenticated, redirect to login")
			next({ name: "login", query: { p: btoa(to.fullPath) }})
			return
		}
		const needRoles = to.matched.find(route => route.meta.roles)
		if(!_.isNil(needRoles)) {
			console.debug("need roles to access route", needRoles.meta.roles)
			if(!store.getters.hasAnyRoles(needRoles.meta.roles)) {
				console.debug("user is not authorized, redirect to unauthorized")
				next({ name: "unauthorized" })
				return
			}
		}
	}
	next()
}

export async function logout() {
	goToLogin()
}

async function logoutTo(userId) {
	try {
		await axios.post("/logout-to", { userId })
		await fetchUserInfo()
	} catch(e) {

	}
}

export function isAuthenticated() {
	return store.getters.isAuthenticated
}

export async function logoutFrom() {
	if(store.getters.isImpersonated) {
		const previousUser = store.getters.accessoPrecedente
		await logoutTo(previousUser.id)
		await store.dispatch("logoutToPrevious")
		if(isAuthenticated()) goToHome()
		else goToLogin()
	}
}

export async function logoutToOriginal() {
	if(store.getters.isImpersonated) {
		const originalUser = store.getters.accessoOriginale
		await logoutTo(originalUser.id)
		await store.dispatch("logoutToOriginal")
		if(isAuthenticated()) goToHome()
		else goToLogin()
	}
}

export function checkAuthentication(error) {
	console.debug("axios security check")
	const response = get(error, "response")
	if(!isNil(response)) {
		if(response.status === 403) {
			return refresh(error.config)
			//else if(!store.getters.isAuthenticated) goToLogin()
		}
		return Promise.reject(response)
	}
	return Promise.reject(error)
}

export async function fetchUserInfo() {
	try {
		const response = await axios.get("/utenti/info")
		const userInfo = get(response, "data")
		if(!isNil(userInfo)) store.commit("setUserInfo", userInfo)
	} catch(e) {
		console.error("Error reading user info", e)
		goToLogin()
	}
}