import NaisWebsocket from "@/libs/websocket"

export default {
	state: {
		connecting: false,
		connected: false,
		connectedAt: null
	},
	getters: {
		websocketConnecting: state => state.connecting,
		websocketConnected: state => state.connected,
		websocketConnectedAt: state => state.connectedAt
	},
	mutations: {
		setConnecting: (state, value) => state.connecting = value,
		setConnected: (state, value) => {
			state.connected = value
			state.connectedAt = new Date()
		},
		clear: state => {
			state.connecting = false
			state.connected = false
			state.connectedAt = null
		}
	},
	actions: {
		async connectWebsocket({ commit, getters }) {
			const isAuthenticated = getters.isAuthenticated
			if(isAuthenticated) try { await NaisWebsocket.connect() }
			catch(e) {
				console.error("error connecting to websocket", e)
				commit("setException", "Si è verificato un errore imprevisto durante la connessione al websocket di Nais")
			}
		},
		async disconnectWebsocket({ commit }) {
			try { await NaisWebsocket.disconnect(true) }
			catch(e) {
				console.error("error disconnecting from websocket", e)
				commit("setException", "Si è verificato un errore imprevisto durante la disconnessione dal websocket di Nais")
			}
		},
		async reconnectWebsocket({ dispatch }) {
			await dispatch("disconnectWebsocket")
			await dispatch("connectWebsocket")
		}
	}
}