<!--<template>
	<div class="ui-vertical-tabs d-flex" :class="{ 'ma-n4': !prominent, 'mini': $vuetify.breakpoint.mdAndDown }">
		<div class="flex-shrink-0">
			<slot name="prepend-tabs"/>
			<v-tabs vertical v-model="tab" v-bind="$attrs">
				<slot name="tabs"/>
			</v-tabs>
			<slot name="append-tabs"/>
		</div>
		<v-divider vertical v-if="!hideDivider" class="mx-0" :class="dividerClass"/>
		<div class="flex-grow-1">
			<v-tabs-items v-model="tab">
				<slot/>
			</v-tabs-items>
		</div>
	</div>
</template>-->

<template lang="pug">
div(class="ui-vertical-tabs d-flex", :class="{ 'ma-n4': !prominent, 'mini': $vuetify.breakpoint.mdAndDown }")
	div(class="flex-shrink-0")
		slot(name="prepend-tabs")
		slot(name="tabs-override")
			v-tabs(vertical, v-model="tab", v-bind="$attrs")
				slot(name="tabs")
	v-divider(vertical, v-if="!hideDivider", class="mx-0", :class="dividerClass")
	div(class="flex-grow-1")
		slot(name="tabs-items-override")
			v-tabs-items(v-model="tab")
				slot
</template>

<script>
	export default {
		name: "ui-vertical-tabs",
		props: {
			hideDivider: Boolean,
			prominent: Boolean,
			dividerClass: [String, Object, Array],
			value: { required: false }
		},
		data() {
			return {
				internalTab: 0
			}
		},
		computed: {
			tab: {
				get() { return this.internalTab },
				set(tab) {
					this.internalTab = tab
					this.$emit("input", this.internalTab)
				}
			}
		},
		watch: {
			value: {
				handler(value) { this.internalTab = this._.isNil(value) ? 0 : value },
				immediate: true
			}
		}
	}
</script>

<style lang="scss" scoped>
.ui-vertical-tabs.mini ::v-deep {
	.v-tab {
		min-width: unset;
	}
}
</style>