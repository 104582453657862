<script>
import { VBtn, VProgressCircular } from "vuetify/lib"

export default {
	name: "ui-timeout-btn",
	props: {
		timeout: { type: Number, required: true },
		step: { type: Number, default: 1 },
		to: [String, Object],
		disabled: Boolean
	},
	data() {
		return {
			timer: null,
			interval: this.timeout
		}
	},
	computed: {
		hasTo() { return !this._.isNil(this.to) },
		timerValue() { return (this.interval / this.timeout) * 100 }
	},
	methods: {
		executeAction() {
			if(this.hasTo) this.$router.push(this.to)
		},
		genContent() {
			return this.$createElement("div", { class: "d-flex align-center" }, [
				this.$createElement("div", { class: "flex-grow-1" }, this.$slots.default),
				!this.disabled && this.genTimer()
			])
		},
		genTimer() {
			return this.$createElement("div", { class: "flex-shrink-1 ml-2" }, this.$scopedSlots.timer ? this.$scopedSlots.timer({ interval: this.interval, timerValue: this.timerValue }) : [this.$createElement(VProgressCircular, {
				props: {
					value: this.timerValue,
					size: 24,
					width: 2
				}
			}, this.interval)])
		},
		genLoader() { if(!this._.isNil(this.$slots.loader)) return this.$createElement("template", { slot: "loader" }, this.$slots.loader) },
		startTimer() { this.timer = setInterval(this.updateTimer, 1000) },
		updateTimer() {
			this.interval -= this.step
			if(this.interval <= 0) {
				clearInterval(this.timer)
				this.executeAction()
			}
		},
		reset() {
			clearInterval(this.timer)
			this.interval = this.timeout
		}
	},
	watch: {
		disabled: {
			handler(disabled) {
				if(!disabled) this.startTimer()
				else if(!this._.isNil(this.timer)) this.reset()
			},
			immediate: true
		}
	},
	render(h) {
		const data = {
			attrs: Object.assign({}, this.$attrs),
			on: Object.assign({}, this.$listeners, { click: this.executeAction })
		}
		const children = [this.genContent(), this.genLoader()]
		return h(VBtn, data, children)
	},
	beforeDestroy() { if(!this._.isNil(this.timer)) clearInterval(this.timer) }
}
</script>